// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css';

// export default function PayoutRequests() {
//     const [payoutRequests, setPayoutRequests] = useState([]);
//     const [filteredPayoutRequests, setFilteredPayoutRequests] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('earningsID');
//     const [selectedRequest, setSelectedRequest] = useState(null);
//     const { API_URL } = config;

//     useEffect(() => {
//         fetchPayoutRequests();
//     }, [API_URL]);

//     const fetchPayoutRequests = async () => {
//         try {
//             const response = await fetch(`${API_URL}/api/payoutrequest/`);
//             const data = await response.json();
//             if (data.status === "success") {

//                 setPayoutRequests(data.payoutRequests);
//                 setFilteredPayoutRequests(data.payoutRequests);
//             } else {
//                 console.error("Error fetching payout requests:", data.message);
//             }
//         } catch (error) {
//             console.error("Error fetching payout requests:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         if (searchTerm === "") {
//             setFilteredPayoutRequests(payoutRequests);
//         } else {
//             const filtered = payoutRequests.filter(request => {
//                 if (searchField === 'earningsID') {
//                     return request.earningsID.toLowerCase().includes(searchTerm.toLowerCase());
//                 }
//                 if (searchField === 'totalAmount') {
//                     return request.totalAmount.toString().includes(searchTerm);
//                 }
//                 return request[searchField].toString().toLowerCase().includes(searchTerm.toLowerCase());
//             });
//             setFilteredPayoutRequests(filtered);
//         }
//     }, [searchTerm, searchField, payoutRequests]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     const handleViewRequest = (request) => {
//         setSelectedRequest(request);
//     };

//     const handleAcceptRequest = () => {
//         fetch(`${API_URL}/api/payoutrequest/${selectedRequest._id}`, {
//             method: 'PUT',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ status: 'completed' })
//         })
//         .then(response => response.json())
//         .then(data => {
//             if (data.status === "success") {
//                 fetchPayoutRequests();
//                 console.log("Accepted request:", selectedRequest);
//                 setSelectedRequest(null);
//             } else {
//                 console.error("Error accepting request:", data.message);
//             }
//         })
//         .catch(error => console.error("Error accepting request:", error));
//     };

//     const handleRejectRequest = () => {
//         fetch(`${API_URL}/api/payoutrequest/${selectedRequest._id}`, {
//             method: 'PUT',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ status: 'cancelled' })
//         })
//         .then(response => response.json())
//         .then(data => {
//             if (data.status === "success") {
//                 fetchPayoutRequests();
//                 console.log("Accepted request:", selectedRequest);
//                 setSelectedRequest(null);
//             } else {
//                 console.error("Error accepting request:", data.message);
//             }
//         })
//         .catch(error => console.error("Error accepting request:", error));
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", maxWidth: "100vw" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", maxWidth: "100vw", padding: "20px"}}>
//             <h1 className='mt-3 mb-4 fw-bold'>Payout Requests</h1>

//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%"}}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={handleSearchFieldChange} 
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="_id">ID</option>
//                     <option value="totalAmount">Total Amount</option>
//                     {/* <option value="earningsID">Earnings ID</option> */}
//                     <option value="status">Status</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>

//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 250px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">ID</th>
//                                 <th scope="col">Total Amount</th>
//                                 {/* <th scope="col">Earnings ID</th> */}
//                                 <th scope="col">Status</th>
//                                 <th scope="col">Created At</th>
//                                 <th scope="col">Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredPayoutRequests
//                                 .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//                                 .map(request => (
//                                     <tr key={request._id}>
//                                         <td>{request._id}</td>
//                                         <td>{request.totalAmount}</td>
//                                         {/* <td>{request.earningsID}</td> */}
//                                         <td>{request.status}</td>
//                                         <td>{new Date(request.createdAt).toLocaleString()}</td>
//                                         <td>
//                                             <button 
//                                                 className="btn btn-primary btn-sm"
//                                                 onClick={() => handleViewRequest(request)}
//                                                 data-bs-toggle="modal"
//                                                 data-bs-target="#payoutRequestModal"
//                                             >
//                                                 View
//                                             </button>
//                                         </td>
//                                     </tr>
//                                 ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>

//             {/* Modal */}
//             <div className="modal fade" id="payoutRequestModal" tabIndex="-1" aria-labelledby="payoutRequestModalLabel" aria-hidden="true">
//                 <div className="modal-dialog">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="payoutRequestModalLabel">Payout Request Details</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div className="modal-body">
//                             {selectedRequest && (
//                                 <div>
//                                     <p><strong>ID:</strong> {selectedRequest._id}</p>
//                                     <p><strong>Total Amount:</strong> {selectedRequest.totalAmount}</p>
//                                     {/* <p><strong>Earnings IDs:</strong> {selectedRequest.earningsID}</p> */}
//                                     <p><strong>Status:</strong> {selectedRequest.status}</p>
//                                     <p><strong>User ID:</strong> {selectedRequest.userID}</p>
//                                     <p><strong>Account Number:</strong> {selectedRequest.accountNumber}</p>
//                                     <p><strong>Created At:</strong> {new Date(selectedRequest.createdAt).toLocaleString()}</p>
//                                     <p><strong>Updated At:</strong> {new Date(selectedRequest.updatedAt).toLocaleString()}</p>
//                                 </div>
//                             )}
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
//                             <button type="button" className="btn btn-success" onClick={handleAcceptRequest} data-bs-dismiss="modal">Accept</button>
//                             <button type="button" className="btn btn-danger" onClick={handleRejectRequest} data-bs-dismiss="modal">Reject</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import config from '../../config';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export default function PayoutRequests() {
    const [payoutRequests, setPayoutRequests] = useState([]);
    const [filteredPayoutRequests, setFilteredPayoutRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('earningsID');
    const [selectedRequest, setSelectedRequest] = useState(null);
    const { API_URL } = config;

    const fetchPayoutRequests = async () => {
        try {
            const response = await fetch(`${API_URL}/api/payoutrequest/`);
            const data = await response.json();
            if (data.status === "success") {
                setPayoutRequests(data.payoutRequests);
                setFilteredPayoutRequests(data.payoutRequests);
            } else {
                console.error("Error fetching payout requests:", data.message);
            }
        } catch (error) {
            console.error("Error fetching payout requests:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPayoutRequests();
    }, [API_URL]);

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredPayoutRequests(payoutRequests);
        } else {
            const filtered = payoutRequests.filter(request =>
                request[searchField]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredPayoutRequests(filtered);
        }
    }, [searchTerm, searchField, payoutRequests]);

    const columns = [
        {
            name: 'ID',
            selector: row => row._id,
            sortable: true,
            wrap: true,
            width: "250px"
        },
        {
            name: 'Total Amount',
            selector: row => row.totalAmount,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: row => new Date(row.createdAt).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 active:bg-blue-700 transition-all duration-200"
                    onClick={() => setSelectedRequest(row)}
                >
                    View
                </button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#1F2937',
                fontWeight: 'bold',
                color: '#F9FAFB',
                fontSize: '16px',
                textTransform: 'uppercase',
                padding: '14px',
                borderBottom: '2px solid #E5E7EB',
            },
        },
        cells: {
            style: {
                padding: '8px',
                color: '#4B5563',
                fontSize: '15px',
                borderBottom: '1px solid #E5E7EB',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                    backgroundColor: '#F3F4F6',
                    transition: 'background-color 0.2s ease-in-out',
                },
                '&:not(:last-of-type)': {
                    borderBottom: '1px solid #E5E7EB',
                },
            },
            highlightOnHoverStyle: {
                backgroundColor: '#E5E7EB',
                borderRadius: '6px',
                outline: '1px solid #D1D5DB',
            },
        },
        pagination: {
            style: {
                backgroundColor: '#F9FAFB',
                padding: '10px 20px',
                borderTop: '1px solid #E5E7EB',
            },
            pageButtonsStyle: {
                borderRadius: '4px',
                height: '30px',
                width: '30px',
                padding: '6px',
                margin: '0 5px',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                color: '#1F2937',
                '&:hover': {
                    backgroundColor: '#E5E7EB',
                },
                '&:disabled': {
                    backgroundColor: '#E5E7EB',
                    color: '#9CA3AF',
                    cursor: 'not-allowed',
                },
            },
        },
    };

    const handleAcceptRequest = async () => {
        try {
            const response = await fetch(`${API_URL}/api/payoutrequest/${selectedRequest._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: 'completed' })
            });
            const data = await response.json();
            if (data.status === "success") {
                fetchPayoutRequests();
                console.log("Accepted request:", selectedRequest);
                setSelectedRequest(null);
            } else {
                console.error("Error accepting request:", data.message);
            }
        } catch (error) {
            console.error("Error accepting request:", error);
        }
    };

    const handleRejectRequest = async () => {
        try {
            const response = await fetch(`${API_URL}/api/payoutrequest/${selectedRequest._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ status: 'cancelled' })
            });
            const data = await response.json();
            if (data.status === "success") {
                fetchPayoutRequests();
                console.log("Rejected request:", selectedRequest);
                setSelectedRequest(null);
            } else {
                console.error("Error rejecting request:", data.message);
            }
        } catch (error) {
            console.error("Error rejecting request:", error);
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center h-[98vh]">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-green-500"></div>
            </div>
        );
    }

    return (
        <div className="p-8">
            <h1 className="text-2xl font-bold mb-4">Payout Requests</h1>

            <div className="flex items-center space-x-4 mb-4">
                <select
                    className="border border-gray-300 rounded px-3 py-2"
                    value={searchField}
                    onChange={e => setSearchField(e.target.value)}
                >
                    <option value="_id">ID</option>
                    <option value="totalAmount">Total Amount</option>
                    <option value="status">Status</option>
                </select>

                <input
                    type="text"
                    placeholder={`Search by ${searchField}...`}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="border border-gray-300 px-3 py-2 flex-grow rounded-lg"
                />
            </div>

            <div className="shadow rounded overflow-hidden">
                <DataTable
                    columns={columns}
                    data={filteredPayoutRequests}
                    customStyles={customStyles}
                    pagination
                    highlightOnHover
                    dense
                />
            </div>

            <Modal
                isOpen={!!selectedRequest}
                onRequestClose={() => setSelectedRequest(null)}
                contentLabel="Payout Request Details"
                ariaHideApp={false}
                className="modal-content bg-white p-6 rounded shadow-lg w-11/12 sm:w-3/4 lg:w-1/3 mx-auto mt-20"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-10 z-50"
                closeTimeoutMS={300}
            >
                {selectedRequest ? (
                    <>
                        <div className="modal-header flex justify-between items-center">
                            <h5 className="modal-title text-xl font-bold">Payout Request Details</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => setSelectedRequest(null)}
                            >

                            </button>
                        </div>
                        <div className="modal-body">
                            <p><strong>ID:</strong> {selectedRequest._id}</p>
                            <p><strong>Total Amount:</strong> {selectedRequest.totalAmount}</p>
                            <p><strong>Earnings IDs:</strong> {selectedRequest.earningsID}</p>
                            <p><strong>Status:</strong> {selectedRequest.status}</p>
                            <p><strong>User ID:</strong> {selectedRequest.userID}</p>
                            <p><strong>Created At:</strong> {new Date(selectedRequest.createdAt).toLocaleString()}</p>
                            <p><strong>Updated At:</strong> {new Date(selectedRequest.updatedAt).toLocaleString()}</p>
                        </div>
                        <div className="modal-footer flex justify-between">
                            <button
                                type="button"
                                className="btn btn-danger bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                                onClick={handleRejectRequest}
                            >
                                Reject
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
                                onClick={handleAcceptRequest}
                            >
                                Accept
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                                onClick={() => setSelectedRequest(null)}
                            >
                                Close
                            </button>
                        </div>
                    </>
                ) : (
                    <div>Loading...</div>
                )}
            </Modal>


        </div>
    );
}
