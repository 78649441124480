// import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
// import "./ScreenCss/Option.css"
// import { useNavigate } from 'react-router-dom';
// import image  from "../Images/Bg.png"



// const backgroundContainerStyle = {
//   backgroundImage: `url(${image})`,
//   backgroundSize: 'cover',
//   backgroundPosition: 'center',
//   minHeight: '100vh',
//   width: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// };

// export default function Option() {
//   // #7fffd4
//   const navigate = useNavigate();
//   return (
//     <div style={{}}>
//       {/* <img src={image} alt="Background" className='w-100 h-fit' /> */}
//       <div className=' ' style={{ display: "flex",flex:1,minHeight:"100vh", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "#503CC9",  width: "100vw", overflowY: "scroll" }} >
//         <div style={backgroundContainerStyle} className='d-flex flex-column align-items-center'>
//         <h1 className='text-white fw-bold mb-5 headtext' >Who are you ?</h1>

//         <div className='myheaddiv '>
//         <div onClick={() => {navigate("signinadmin")}} className='opcards gap-4 card rounded-circle fw-bold  d-flex flex-column  justify-content-center align-items-center'>
//         <FontAwesomeIcon icon={faUser}  className='cardfa m-2' />
//             <div className=' textfa'>Admin</div>
//         </div>
//         <div onClick={() => {navigate("signinsubadmin")}} className='opcards gap-4 card rounded-circle fw-bold d-flex flex-column  justify-content-center align-items-center'>
//         <FontAwesomeIcon icon={faUsers}  className='cardfa m-2' />
//             <div className='  textfa'>Sub-Admin</div>
//         </div>
//       </div>
//         </div>

//       </div>
//     </div>
//   )
// }


// import React from 'react'

// const Option = () => {
//   return (
//     <div className='flex justify-center items-center h-screen bg-white w-screen'>
//       Redirecting.....
//     </div>
//   )
// }

// export default Option


// import React, { useEffect, useState } from "react";

// const DetectDevice = () => {
//   const [device, setDevice] = useState("");

//   useEffect(() => {
//     const detectedDevice = detectDevice();
//     setDevice(detectedDevice);
//     console.log(`Website is opened on: ${detectedDevice}`);
//   }, []);

//   const detectDevice = () => {
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;

//     if (/android/i.test(userAgent)) {
//       return "Android";
//     }

//     if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//       return "iOS";
//     }

//     return "Other";
//   };

//   return (
//     <div className="text-center p-5">
//       <h1 className="text-2xl font-bold">Redirecting...</h1>
//       <p className="mt-3">
//         <span className="font-semibold text-blue-600">{device}</span>
//       </p>
//     </div>
//   );
// };

// export default DetectDevice;

import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DynamicComponent from "../components/Invite/DynamicComponent";
import DefaultComponent from "../components/Invite/DefaultComponent";
import { useEffect } from "react";
import { db } from '../repository/firebase_init';
import { collection, doc, setDoc } from "firebase/firestore";

const Option = () => {
  const { type } = useParams();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  useEffect(() => {
    const saveCodeToFirestore = async (type, code) => {
      try {
        const docRef = doc(collection(db, "public"), type || "unknown-type");
        await setDoc(docRef, { code }, { merge: true });
        console.log("Code saved successfully");
        if (type === "nukkad-foods") {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.nukkadfoods.nukkad_foods_new";
        } else if (type === "nukkad") {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.nukkadfoods.nukkad_partner";
        } else if (type === "nukkad-delivery-partner") {
          window.location.href =
            "https://play.google.com/store/apps/details?id=com.nukkadfoods.delivery_partner_new";
        }
      } catch (error) {
        console.error("Error saving code to Firestore:", error);
      }
    };
    if (code) saveCodeToFirestore(type, code);
  }, [type, code]);

  return (
    <div>
      {type ? (
        <DynamicComponent type={type} code={code} />
      ) : (
        <DefaultComponent />
      )}
    </div>
  );
};

export default Option;
