// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css'; // Import your custom CSS file for styling

// export default function Orders() {
//     const [orders, setOrders] = useState([]);
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('orderId'); // Default search field
//     const { API_URL } = config;

//     useEffect(() => {
//         fetch(`${API_URL}/api/order/getAllOrders`)
//             .then(res => res.json())
//             .then(data => {
//                 if (data.orders.length > 0) { setOrders(data.orders); }
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching orders:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         // Filter orders based on search term and field
//         if (orders.length > 0) {
//             const filtered = orders.filter(order =>
//                 order[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//             );
//             setFilteredOrders(filtered);
//         }
//     }, [searchTerm, searchField, orders]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column' style={{ minHeight: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column' style={{ minHeight: "100vh", maxWidth: "100vw", justifyContent: "flex-start", alignItems: "center" }}>
//             <h1 className='m-5 fw-bold w-100 text-center'>Orders</h1>
//             <div className="mb-3 d-flex justify-content-between align-items-center " style={{ width: "90%" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={handleSearchFieldChange} 
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="uid">Restaurant ID</option>
//                     <option value="orderId">Order ID</option>
//                     <option value="date">Date</option>
//                     <option value="time">Time</option>
//                     <option value="paymentMethod">Payment Method</option>
//                     <option value="totalCost">Total Cost</option>
//                     <option value="status">Status</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>
//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%",  height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">Restaurant ID</th>
//                                 <th scope="col">Order ID</th>
//                                 <th scope="col">Date</th>
//                                 <th scope="col">Time</th>
//                                 <th scope="col">Payment Method</th>
//                                 <th scope="col">Total Cost</th>
//                                 <th scope="col">Status</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredOrders.map(order => (
//                                 <tr key={order._id}>
//                                     <td>{order.uid}</td>
//                                     <td>{order.orderId}</td>
//                                     <td>{order.date}</td>
//                                     <td>{order.time}</td>
//                                     <td>{order.paymentMethod}</td>
//                                     <td>{order.totalCost}</td>
//                                     <td>{order.status}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css';

// export default function Orders() {
//     const [orders, setOrders] = useState([]);
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('orderId');
//     const { API_URL } = config;

//     useEffect(() => {
//         fetch(`${API_URL}/api/order/getAllOrders`)
//             .then(res => res.json())
//             .then(data => {
//                 if (data.orders.length > 0) { setOrders(data.orders); }
//                 setLoading(false);
//                 console.log(data.orders)
//             })
//             .catch(error => {
//                 console.error("Error fetching orders:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         if (orders.length > 0) {
//             const filtered = orders.filter(order =>
//                 order[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//             );
//             setFilteredOrders(filtered);
//         }
//     }, [searchTerm, searchField, orders]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column' style={{ minHeight: "98vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='p-8'>
//             {/* <div className='d-flex flex-column' style={{ minHeight: "100vh", maxWidth: "100vw", justifyContent: "flex-start", alignItems: "center" }}></div> */}
//             <h1 className=''>Orders</h1>
//             <div className="mb-3 d-flex justify-content-between align-items-center " style={{ width: "90%" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={handleSearchFieldChange} 
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="uid">Restaurant ID</option>
//                     <option value="orderId">Order ID</option>
//                     <option value="date">Date</option>
//                     <option value="time">Time</option>
//                     <option value="paymentMethod">Payment Method</option>
//                     <option value="totalCost">Total Cost</option>
//                     <option value="status">Status</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>
//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%",  height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">Restaurant ID</th>
//                                 <th scope="col">Order ID</th>
//                                 <th scope="col">Date</th>
//                                 <th scope="col">Time</th>
//                                 <th scope="col">Payment Method</th>
//                                 <th scope="col">Total Cost</th>
//                                 <th scope="col">Status</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredOrders.map(order => (
//                                 <tr key={order._id}>
//                                     <td>{order.uid}</td>
//                                     <td>{order.orderId}</td>
//                                     <td>{order.date}</td>
//                                     <td>{order.time}</td>
//                                     <td>{order.paymentMethod}</td>
//                                     <td>{order.totalCost}</td>
//                                     <td>{order.status}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import DataTable from 'react-data-table-component';
// import config from '../../config';

// export default function Orders() {
//     const [orders, setOrders] = useState([]);
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('orderId');
//     const { API_URL } = config;

//     useEffect(() => {
//         fetch(`${API_URL}/api/order/getAllOrders`)
//             .then((res) => res.json())
//             .then((data) => {
//                 if (data.orders.length > 0) {
//                     setOrders(data.orders);
//                     console.log(data.orders)
//                     setFilteredOrders(data.orders);
//                 }
//                 setLoading(false);
//             })
//             .catch((error) => {
//                 console.error('Error fetching orders:', error);
//                 setLoading(false);
//             });
//     }, []);

//     const handleSearchTermChange = (event) => {
//         setSearchTerm(event.target.value);
//         const filtered = orders.filter((order) =>
//             order[searchField].toString().toLowerCase().includes(event.target.value.toLowerCase())
//         );
//         setFilteredOrders(filtered);
//     };

//     const handleSearchFieldChange = (event) => {
//         setSearchField(event.target.value);
//     };

//     const columns = [
//         {
//             name: 'Restaurant ID',
//             selector: (row) => row.uid,
//             sortable: true,
//         },
//         {
//             name: 'Order ID',
//             selector: (row) => row.orderId,
//             sortable: true,
//         },
//         {
//             name: 'Date',
//             selector: (row) => row.date,
//             sortable: true,
//         },
//         {
//             name: 'Time',
//             selector: (row) => row.time,
//             sortable: true,
//         },
//         {
//             name: 'Payment Method',
//             selector: (row) => row.paymentMethod,
//             sortable: true,
//         },
//         {
//             name: 'Total Cost',
//             selector: (row) => row.totalCost,
//             sortable: true,
//         },
//         {
//             name: 'Status',
//             selector: (row) => row.status,
//             sortable: true,
//         },
//     ];

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center min-h-screen">
//                 <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="p-6">
//             <h1 className="text-2xl font-bold mb-4">Orders</h1>
//             <div className="flex items-center justify-between mb-4">
//                 <select
//                     className="border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring focus:ring-blue-200"
//                     value={searchField}
//                     onChange={handleSearchFieldChange}
//                 >
//                     <option value="uid">Restaurant ID</option>
//                     <option value="orderId">Order ID</option>
//                     <option value="date">Date</option>
//                     <option value="time">Time</option>
//                     <option value="paymentMethod">Payment Method</option>
//                     <option value="totalCost">Total Cost</option>
//                     <option value="status">Status</option>
//                 </select>
//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="border border-gray-300 rounded px-4 py-2 w-1/2 focus:outline-none focus:ring focus:ring-blue-200"
//                 />
//             </div>
//             <div className="bg-white shadow-md rounded-lg overflow-hidden">
//                 <DataTable
//                     columns={columns}
//                     data={filteredOrders}
//                     pagination
//                     highlightOnHover
//                     className="table-auto"
//                     customStyles={{
//                         header: {
//                             style: {
//                                 backgroundColor: '#f3f4f6',
//                                 fontWeight: 'bold',
//                                 fontSize: '16px',
//                             },
//                         },
//                         rows: {
//                             style: {
//                                 '&:nth-child(even)': {
//                                     backgroundColor: '#f9fafb',
//                                 },
//                                 '&:hover': {
//                                     backgroundColor: '#f1f5f9',
//                                     cursor: 'pointer',
//                                 },
//                             },
//                         },
//                         pagination: {
//                             style: {
//                                 backgroundColor: '#f9fafb',
//                                 padding: '10px',
//                                 borderTop: '1px solid #e5e7eb',
//                             },
//                         },
//                     }}
//                 />
//             </div>
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import config from '../../config';

export default function Orders() {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);
    const { API_URL } = config;

    useEffect(() => {
        fetch(`${API_URL}/api/order/getAllOrders`)
            .then((res) => res.json())
            .then((data) => {
                if (data.orders.length > 0) {
                    setOrders(data.orders);
                    console.log(data.orders)
                    setFilteredOrders(data.orders);
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching orders:', error);
                setLoading(false);
            });
    }, []);

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);

        const filtered = orders.filter((order) =>
            order.orderId.toLowerCase().includes(term) ||
            order.orderByName.toLowerCase().includes(term) ||
            order.paymentMethod.toLowerCase().includes(term) ||
            order.status.toLowerCase().includes(term)
        );

        setFilteredOrders(filtered);
    };


    const getStatusColor = (status) => {
        switch (status) {
            case 'Accepted':
                return '#3B82F6';
            case 'Preparing':
                return '#F59E0B';
            case 'Completed':
                return '#10B981';
            case 'On the way':
                return '#F59E0B';
            case 'Delivered':
                return '#10B981';
            case 'Ready':
                return '#6366F1';
            case 'Declined':
                return 'red';
            default:
                return '#6B7280';
        }
    };


    const columns = [
        {
            name: 'Order ID',
            selector: (row) => row.orderId,
            sortable: true,
            width: "220px"
        },
        {
            name: 'Customer Name',
            selector: (row) => row.orderByName,
            sortable: true,
        },
        {
            name: 'Payment Method',
            selector: (row) => row.paymentMethod,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            cell: (row) => (
                <span
                    style={{
                        padding: '5px 10px',
                        textAlign: "center",
                        width: "110px",
                        borderRadius: '5px',
                        color: '#fff',
                        backgroundColor: getStatusColor(row.status),
                        fontWeight: '600',
                    }}
                >
                    {row.status}
                </span>
            ),
        },

        {
            name: 'Total Cost',
            selector: (row) => `₹${row.totalCost.toFixed(2)}`,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <button
                    onClick={() => setSelectedOrder(row)}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Details
                </button>
            ),
        },
    ];

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-500" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    const customStyles = {
        header: {
            style: {
                fontSize: '1.25rem',
                fontWeight: '700',
                textTransform: 'uppercase',
                color: '#374151',
                backgroundColor: '#F9FAFB',
                borderBottom: '2px solid #E5E7EB',
            },
        },
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: '600',
                color: '#1F2937',
                textTransform: 'uppercase',
                backgroundColor: '#F3F4F6',
                borderBottom: '1px solid #D1D5DB',
                padding: '10px',
            },
        },
        cells: {
            style: {
                fontSize: '0.975rem',
                color: '#4B5563',
                borderBottom: '1px solid #E5E7EB',
                padding: '10px',
            },
        },
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#F9FAFB',
                    cursor: 'pointer',
                },
            },
        },
        pagination: {
            style: {
                fontSize: '0.875rem',
                backgroundColor: '#F9FAFB',
                color: '#6B7280',
                borderTop: '1px solid #E5E7EB',
                paddingTop: '10px',
                paddingBottom: '10px',
                justifyContent: 'end',
            },
        },
    };


    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Orders</h1>
            <input
                type="text"
                placeholder="Search by Order ID or Customer Name..."
                value={searchTerm}
                onChange={handleSearch}
                className="border border-gray-300 px-4 py-2 w-full focus:outline-none focus:ring focus:ring-blue-200 mb-4 rounded-lg"
            />

            <DataTable
                columns={columns}
                data={filteredOrders}
                pagination
                highlightOnHover
                customStyles={customStyles}
            />

            {/* Modal */}
            {/* {selectedOrder && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-1/2 h-[90vh]">
                        <h2 className="text-xl font-bold mb-4">Order Details</h2>
                        <pre className="bg-gray-100 p-4 rounded overflow-x-auto text-sm">
                            {JSON.stringify(selectedOrder, null, 2)}
                        </pre>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={() => setSelectedOrder(null)}
                                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )} */}

            {selectedOrder && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start pt-10 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-1/2 h-[90vh] overflow-y-auto">
                        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Order Details</h2>

                        <div className="space-y-4">
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Order ID:</span>
                                <span className="text-gray-600">{selectedOrder.orderId}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Customer Name:</span>
                                <span className="text-gray-600">{selectedOrder.orderByName}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Order Type:</span>
                                <span className="text-gray-600">{selectedOrder.ordertype}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Payment Method:</span>
                                <span className="text-gray-600">{selectedOrder.paymentMethod}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Status:</span>
                                <span className="text-gray-600">{selectedOrder.status}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Total Cost:</span>
                                <span className="text-gray-600">₹{selectedOrder.totalCost.toFixed(2)}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Time:</span>
                                <span className="text-gray-600">{selectedOrder.time}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Time to Prepare:</span>
                                <span className="text-gray-600">{selectedOrder.timetoprepare}</span>
                            </div>
                            <div className="flex justify-between">
                                <span className="font-semibold text-gray-700">Date:</span>
                                <span className="text-gray-600">{new Date(selectedOrder.date).toLocaleDateString()}</span>
                            </div>
                        </div>

                        <div className="mt-6 border-t pt-4">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">Items</h3>
                            <table className="min-w-full table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 text-left font-semibold text-gray-700">Item Name</th>
                                        <th className="px-4 py-2 text-left font-semibold text-gray-700">Quantity</th>
                                        <th className="px-4 py-2 text-left font-semibold text-gray-700">Unit Cost</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedOrder.items.map((item, index) => (
                                        <tr key={index} className="border-t">
                                            <td className="px-4 py-1 text-gray-600">{item.itemName}</td>
                                            <td className="px-4 py-1 text-gray-600">{item.itemQuantity}</td>
                                            <td className="px-4 py-1 text-gray-600">₹{item.unitCost.toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-6 border-t pt-4">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">Delivery Address</h3>
                            <p className="text-gray-600">{selectedOrder.deliveryAddress}</p>
                        </div>

                        <div className="mt-6 border-t pt-4">
                            <h3 className="text-xl font-semibold text-gray-800 mb-4">Charges</h3>
                            <table className="min-w-full table-auto">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-2 text-left font-semibold text-gray-700">Charge Type</th>
                                        <th className="px-4 py-2 text-left font-semibold text-gray-700">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="border-t">
                                        <td className="px-4 py-2 text-gray-600">Convenience Fee</td>
                                        <td className="px-4 py-2 text-gray-600">₹{selectedOrder.convinenceFee}</td>
                                    </tr>
                                    <tr className="border-t">
                                        <td className="px-4 py-2 text-gray-600">Delivery Charge</td>
                                        <td className="px-4 py-2 text-gray-600">₹{selectedOrder.deliveryCharge}</td>
                                    </tr>
                                    <tr className="border-t">
                                        <td className="px-4 py-2 text-gray-600">GST</td>
                                        <td className="px-4 py-2 text-gray-600">₹{selectedOrder.gst.toFixed(2)}</td>
                                    </tr>
                                    <tr className="border-t">
                                        <td className="px-4 py-2 text-gray-600">Driver Tip</td>
                                        <td className="px-4 py-2 text-gray-600">₹{selectedOrder.drivertip}</td>
                                    </tr>
                                    <tr className="border-t">
                                        <td className="px-4 py-2 text-gray-600">Item Amount</td>
                                        <td className="px-4 py-2 text-gray-600">₹{selectedOrder.itemAmount}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={() => setSelectedOrder(null)}
                                className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
