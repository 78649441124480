import React, { useEffect, useState } from "react";

const DetectDevice = () => {
  const [device, setDevice] = useState("");

  useEffect(() => {
    const detectDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Other";
    };

    const detectedDevice = detectDevice();
    setDevice(detectedDevice);
    console.log(`Website is opened on: ${detectedDevice}`);
  }, []);

  return (
    <div className="mt-3">
      <h3 className="text-lg">
        Detected Device: <span className="text-blue-600 font-semibold">{device}</span>
      </h3>
    </div>
  );
};

export default DetectDevice;
