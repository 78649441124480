// import React, { useEffect, useState, useRef } from 'react';
// import config from '../../config';
// import { db } from '../../repository/firebase_init';
// import { collection, onSnapshot, doc, updateDoc } from 'firebase/firestore';

// export default function Complaints() {
//     const [complaints, setComplaints] = useState([]);
//     const [filteredComplaints, setFilteredComplaints] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('contact');
//     const [selectedComplaint, setSelectedComplaint] = useState(null);
//     const [newComment, setNewComment] = useState('');
//     const { API_URL } = config;

//     const messagesEndRef = useRef(null);
//     const modalOpenRef = useRef(false);

//     useEffect(() => {
//         const unsubscribe = onSnapshot(collection(db, 'chat'), (snapshot) => {
//             const data = snapshot.docs.map(doc => ({
//                 contact: doc.id,
//                 data: doc.data(),
//                 lastModified: doc.data().lastModified.seconds * 1000
//             }));
//             const sortedData = data.sort((a, b) => b.lastModified - a.lastModified);
//             setComplaints(sortedData);
//             setFilteredComplaints(sortedData);
//             setLoading(false);
//         }, (error) => {
//             console.error("Error fetching complaints:", error);
//             setLoading(false);
//         });

//         return () => unsubscribe();
//     }, [API_URL]);

//     useEffect(() => {
//         if (searchTerm === "") {
//             setFilteredComplaints(complaints);
//         } else {
//             const filtered = complaints.filter(complaint =>
//                 complaint[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//             );
//             setFilteredComplaints(filtered);
//         }
//     }, [searchTerm, searchField, complaints]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     const handleViewComplaint = (complaint) => {
//         setSelectedComplaint({
//             ...complaint.data,
//             contact: complaint.contact,
//             lastModified: complaint.lastModified
//         });
//         setNewComment('');
//         modalOpenRef.current = true;
//     };

//     const handleSendComment = async () => {
//         if (selectedComplaint) {
//             await updateComplaint(selectedComplaint.contact, newComment, 'In Progress');
//         }
//     };

//     const updateComplaint = async (id, comment, status) => {
//         try {
//             const complaintRef = doc(db, 'chat', id);
//             const newMessage = {
//                 sender: 'nukkad_admin',
//                 text: comment,
//                 type: 'text',
//                 timeStamp: {
//                     seconds: Math.floor(Date.now() / 1000),
//                     nanoseconds: 0
//                 }
//             };

//             await updateDoc(complaintRef, {
//                 messages: [...selectedComplaint.messages, newMessage],
//                 lastModified: {
//                     seconds: Math.floor(Date.now() / 1000),
//                     nanoseconds: 0
//                 }
//             });

//             setSelectedComplaint(null);
//         } catch (error) {
//             console.error("Error updating complaint:", error);
//         }
//     };

//     useEffect(() => {
//         const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
//         const handleScroll = async () => {
//             await delay(1000);
//             if (messagesEndRef.current) {
//                 document.getElementById('allmessagesclick').click();
//             }
//         };
//         handleScroll();
//     }, [selectedComplaint, messagesEndRef.current]);

//     const handleModalClose = () => {
//         modalOpenRef.current = false;
//         setSelectedComplaint(null);
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column bg-white' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column' style={{ height: "100vh", maxWidth: "100vw", padding: "20px", justifyContent: "center", alignItems: "center" }}>
//             <h1 className='mt-3 mb-4 fw-bold'>Complaints</h1>

//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
//                 <select
//                     className="form-select me-3"
//                     value={searchField}
//                     onChange={handleSearchFieldChange}
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="contact">Contact</option>
//                     <option value="lastModified">Last Modified</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>

//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 250px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">Contact</th>
//                                 <th scope="col">Last Modified</th>
//                                 <th scope="col">Unread Messages</th>
//                                 <th scope="col">Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredComplaints.map(complaint => (
//                                 <tr key={complaint.contact}>
//                                     <td>{complaint.contact}</td>
//                                     <td>{new Date(complaint.lastModified).toLocaleString()}</td>
//                                     <td>{complaint.data.unreadBySupport}</td>
//                                     <td>
//                                         <button
//                                             className="btn btn-primary btn-sm"
//                                             onClick={() => handleViewComplaint(complaint)}
//                                             data-bs-toggle="modal"
//                                             data-bs-target="#complaintModal"
//                                         >
//                                             Respond
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>

//             <div className="modal fade" id="complaintModal" tabIndex="-1" aria-labelledby="complaintModalLabel" aria-hidden="true" onHide={handleModalClose} scroll>
//                 <div className="modal-dialog modal-lg">
//                     <div className="modal-content">
//                         <div className="modal-header">
//                             <h5 className="modal-title" id="complaintModalLabel">Complaint Details</h5>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleModalClose}></button>
//                         </div>
//                         <div className="modal-body">
//                             {selectedComplaint && (
//                                 <div>
//                                     <h6>Unread Messages: {selectedComplaint.unreadBySupport}</h6>
//                                     <h6>Last Modified: {new Date(selectedComplaint.lastModified).toLocaleString()}</h6>
//                                     <h6>Messages:</h6>
//                                     <div style={{ maxHeight: '200px', overflowY: 'auto', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '10px' }}>
//                                         {selectedComplaint.messages.map((message, index) => (
//                                             <div key={index} className="mb-2">
//                                                 <strong>{message.sender}:</strong> {message.text}
//                                                 <small className="text-muted d-block">{new Date(message.timeStamp.seconds * 1000).toLocaleString()}</small>
//                                             </div>
//                                         ))}
//                                         <div ref={messagesEndRef}></div>
//                                     </div>

//                                     <div />
//                                     <hr />
//                                     <div id="allmessagesclick" style={{ display: 'none' }} onClick={() => { messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' }) }}>Click me</div>
//                                     <h6>Add a Comment:</h6>
//                                     <input
//                                         type="text"
//                                         className="form-control me-2"
//                                         placeholder="Add a comment..."
//                                         value={newComment}
//                                         onChange={(e) => setNewComment(e.target.value)}
//                                     />
//                                 </div>
//                             )}
//                         </div>
//                         <div className="modal-footer">
//                             <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={handleSendComment}>Send</button>
//                             <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={() => updateComplaint(selectedComplaint.contact, 'Case closed by admin', 'Closed')}>Close Case</button>
//                             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleModalClose}>Close</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }




import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import config from '../../config';
import { db } from '../../repository/firebase_init';
import { collection, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { MessageSeparator } from '@chatscope/chat-ui-kit-react';
import { format } from 'date-fns';
import { FaAngleLeft } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../../repository/firebase_init'
import backgroundImage from '../../Images/wallapaper.jpeg';

export default function Complaints() {
    const [complaints, setComplaints] = useState([]);
    // const [filteredComplaints, setFilteredComplaints] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('contact');
    const [selectedComplaint, setSelectedComplaint] = useState(null);
    const [newComment, setNewComment] = useState('');
    const [openImageVideoUpload, setOpenImageVideoUpload] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { API_URL } = config;

    const messagesEndRef = useRef(null);
    const modalOpenRef = useRef(false);
    const img = 'https://firebasestorage.googleapis.com/v0/b/nukkad-foods.appspot.com/o/chat-images%2F1731140189032-young-manager-wearing-a-black-t-shirt-in-a-cafe-fa.png?alt=media&token=c23b9ea3-694c-436a-92df-0874e9ef6a63';
    const filteredComplaints = useMemo(() => {
        if (!searchTerm) return complaints;
        return complaints.filter(complaint =>
            complaint[searchField].toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm, searchField, complaints]);


    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'chat'), (snapshot) => {
            const data = snapshot.docs.map(doc => ({
                contact: doc.id,
                data: doc.data(),
                lastModified: doc.data().lastModified.seconds * 1000
            }));
            const sortedData = data.sort((a, b) => b.lastModified - a.lastModified);
            setComplaints(sortedData);
            setLoading(false);
        }, (error) => {
            console.error("Error fetching complaints:", error);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    // useEffect(() => {
    //     if (searchTerm === "") {
    //         setFilteredComplaints(complaints);
    //     } else {
    //         const filtered = complaints.filter(complaint =>
    //             complaint[searchField].toLowerCase().includes(searchTerm.toLowerCase())
    //         );
    //         setFilteredComplaints(filtered);
    //     }
    // }, [searchTerm, searchField, complaints]);
    useEffect(() => {
        if (selectedComplaint) {
            const unsubscribe = onSnapshot(doc(db, 'chat', selectedComplaint.contact), (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    // Update selectedComplaint with the latest messages
                    setSelectedComplaint(prev => ({
                        ...prev,
                        messages: data.messages || [] // Update messages in selectedComplaint
                    }));
                }
            });

            return () => unsubscribe();
        }
    }, [selectedComplaint]);
    useEffect(() => {
        console.log('selectedComplaint updated:', selectedComplaint);
        const updateCount = async () => {
            await updateComplaint('', '', '', true);
        };
        if (selectedComplaint) {
            updateCount();
        }
    }, [selectedComplaint]);
    useEffect(() => {
        const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
        const handleScroll = async () => {
            await delay(1000);
            if (messagesEndRef.current) {
                document.getElementById('allmessagesclick').click();
            }
        };
        setTimeout(() => {
            handleScroll();
        }, [2600])
    }, [messagesEndRef.current]);
    const handleSearchTermChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleSearchFieldChange = event => {
        setSearchField(event.target.value);
    };

    const handleViewComplaint = async (complaint) => {
        setSelectedComplaint({
            ...complaint.data,
            contact: complaint.contact,
            lastModified: complaint.lastModified
        });
        console.log('Complain data: ', complaint.data);
        setNewComment('');
        setIsModalOpen(true); // Set modal open state
    };

    const handleModalClose = () => {
        setIsModalOpen(false); // Reset modal open state
        setSelectedComplaint(null);
    };

    const handleSendComment = async () => {
        if (selectedComplaint) {
            setNewComment('')
            await updateComplaint(selectedComplaint.contact, newComment, 'In Progress', true);
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const updateComplaint = async (id, comment, status, markAsRead = false) => {
        if (!id) {
            console.error("Invalid document ID.");
            return;
        }
        else {
            try {
                const complaintRef = doc(db, 'chat', id);
                const newMessage = {
                    sender: 'nukkad_admin',
                    text: comment,
                    type: 'text',
                    timeStamp: {
                        seconds: Math.floor(Date.now() / 1000),
                        nanoseconds: 0
                    }
                };

                const updateData = {
                    messages: [...selectedComplaint.messages, newMessage],
                    lastModified: {
                        seconds: Math.floor(Date.now() / 1000),
                        nanoseconds: 0
                    },
                    ...(markAsRead && { unreadBySupport: 0 }) // Set unreadBySupport to 0 if markAsRead is true
                };
                await updateDoc(complaintRef, updateData);
                // setSelectedComplaint(null);
            } catch (error) {
                console.error("Error updating complaint:", error);
            }
        }
    };

    // const handleModalClose = () => {
    //     modalOpenRef.current = false;
    //     setSelectedComplaint(null);
    // };

    if (loading) {
        return (
            <div className='d-flex flex-column bg-white' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }
    const isUserMessage = (sender) => {
        const phoneRegex = /^\+?(\d{1,4})?[-.\s]?(\(?\d{3}\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/;
        return phoneRegex.test(sender);
    };
    const handleUploadImageVideoOpen = () => {
        setOpenImageVideoUpload(preve => !preve)
    }
    const updateMediaComplaint = async (id, comment, status, markAsRead = false) => {
        try {
            const complaintRef = doc(db, 'chat', id);
            const updateData = {
                messages: [...selectedComplaint.messages, comment],
                lastModified: {
                    seconds: Math.floor(Date.now() / 1000),
                    nanoseconds: 0
                },
                ...(markAsRead && { unreadBySupport: 0 }) // Set unreadBySupport to 0 if markAsRead is true
            };
            await updateDoc(complaintRef, updateData);
            // setSelectedComplaint(null);
        } catch (error) {
            console.error("Error updating complaint:", error);
        }
    }
    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const downloadUrl = await uploadImage(file);
                const newMessage = {
                    sender: 'nukkad_admin', // Replace with the user's phone number if applicable
                    text: file.name, // Store the filename in the text field
                    timeStamp: {
                        seconds: Math.floor(Date.now() / 1000),
                        nanoseconds: 0
                    },
                    type: 'file', // Indicate that this is a file message
                    filename: file.name, // Store the original filename
                    downloadUrl: downloadUrl // The URL for downloading the file
                };
                await updateMediaComplaint(selectedComplaint.contact, newMessage, 'In Progress', true);
                setOpenImageVideoUpload(false);
            } catch (error) {
                console.error("Error uploading image/video:", error);
            }
        }
    };
    const uploadImage = async (file) => {
        const imageName = `chat-images/${Date.now()}-${file.name}`
        const imageRef = ref(storage, imageName)
        await uploadBytes(imageRef, file)
        return await getDownloadURL(imageRef)
    }

    return (
        <div className='d-flex flex-column' style={{ height: "100vh", maxWidth: "100vw", padding: "20px", justifyContent: "center", alignItems: "center" }}>
            <h1 className='mt-3 mb-4 fw-bold'>Complaints</h1>

            <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
                <select
                    className="form-select me-3"
                    value={searchField}
                    onChange={handleSearchFieldChange}
                    style={{ width: "auto", minWidth: "150px" }}
                >
                    <option value="contact">Contact</option>
                    <option value="lastModified">Last Modified</option>
                </select>

                <input
                    type="text"
                    placeholder={`Search by ${searchField}...`}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="form-control"
                    style={{ width: "60%" }}
                />
            </div>

            <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 250px)", borderRadius: "15px", overflow: "hidden" }}>
                <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
                    <table className="table table-hover mb-0">
                        <thead className="table-light sticky-top">
                            <tr>
                                <th scope="col">Contact</th>
                                <th scope="col">Last Modified</th>
                                <th scope="col">Unread Messages</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredComplaints.map(complaint => (
                                <tr key={complaint.contact}>
                                    <td>{complaint.contact}</td>
                                    <td>{new Date(complaint.lastModified).toLocaleString()}</td>
                                    <td>{complaint.data.unreadBySupport}</td>
                                    <td>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => handleViewComplaint(complaint)}
                                            data-bs-toggle="modal"
                                            data-bs-target="#complaintModal"
                                        >
                                            Respond
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal fade" id="complaintModal" tabIndex="-1" aria-labelledby="complaintModalLabel" aria-hidden="true" onHide={handleModalClose}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="complaintModalLabel">Complaint Details</h5>
                            <div style={{ width: '20%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <button type="button" className="btn btn-warning" data-bs-dismiss="modal" onClick={() => updateComplaint(selectedComplaint.contact, 'Case closed by admin', 'Closed')}>
                                    Close Case
                                </button>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleModalClose}></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {selectedComplaint && (
                                <div>
                                    <div
                                        style={{
                                            maxHeight: '670px',
                                            overflowY: 'auto', backgroundColor: '#f8f9fa', borderRadius: '5px', padding: '10px', backgroundImage: `url(${backgroundImage})`
                                        }}
                                        className='bg-no-repeat bg-cover'
                                    >
                                        {selectedComplaint.messages.map((msg, index) => {
                                            const messageDate = new Date(msg.timeStamp.seconds * 1000);
                                            const messageDateString = format(messageDate, 'EEEE, dd MMMM yyyy');
                                            const messageTimeString = format(messageDate, 'hh:mm a');
                                            const showSeparator = index === 0 || messageDateString !== format(new Date(selectedComplaint.messages[index - 1].timeStamp.seconds * 1000), 'EEEE, dd MMMM yyyy');

                                            return (
                                                <React.Fragment key={index}>
                                                    {showSeparator && (
                                                        <MessageSeparator
                                                            as="h2"
                                                            content={messageDateString}
                                                            className='bg-indigo-100'
                                                        />
                                                    )}
                                                    <div className={`flex ${isUserMessage(msg.sender) ? 'flex-col gap-2' : 'justify-end'} py-0 mx-2`}>
                                                        <div className='flex flex-col gap-1 w-full max-w-2xl'>
                                                            <div className={`p-2 py-1 rounded-lg w-fit max-w-full md:max-w-md lg:max-w-lg ${isUserMessage(msg.sender) ? 'bg-white' : 'ml-auto bg-teal-100'}`}>
                                                                {msg.type === 'text' ? (
                                                                    <div>
                                                                        <p className='px-2 text-md'>{msg.text}</p>
                                                                        <p className='text-xs mt-1 ml-auto w-fit'>{messageTimeString}</p>
                                                                    </div>
                                                                ) : msg.type === 'file' ? (
                                                                    msg.filename && msg.filename.endsWith('.mp4') ? (
                                                                        <div className='w-full relative max-w-[400px]'>
                                                                            <video
                                                                                src={msg.downloadUrl}
                                                                                controls
                                                                                className='w-full h-full object-scale-down'
                                                                            />
                                                                            <p className='text-xs mt-1 ml-auto w-fit'>{messageTimeString}</p>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='w-full relative max-w-[300px] md:max-w-lg'>
                                                                            <img
                                                                                src={msg.downloadUrl}
                                                                                className='w-full h-full object-scale-down'
                                                                                alt="sent media"
                                                                            />
                                                                            <p className='text-xs mt-1 ml-auto w-fit'>{messageTimeString}</p>
                                                                        </div>
                                                                    )
                                                                ) : null}
                                                            </div>
                                                            <div ref={messagesEndRef} />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                    <div />
                                    <hr />
                                    <section className='h-16 bg-white flex items-center px-3'>
                                        <div className='relative'>
                                            <button
                                                onClick={handleUploadImageVideoOpen}
                                                className='flex justify-center items-center w-11 h-11 rounded-full hover:bg-[#05adb5] hover:text-white'
                                            >
                                                <FaPlus size={20} />
                                            </button>
                                            {openImageVideoUpload && (
                                                <div className='bg-white shadow rounded absolute bottom-14 w-36 p-2'>
                                                    <form>
                                                        <label htmlFor='uploadImage' className='flex items-center p-2 px-3 gap-3 hover:bg-slate-200 cursor-pointer'>
                                                            <div className='text-primary'>
                                                                <FaImage size={18} />
                                                            </div>
                                                            <p>Media</p>
                                                        </label>
                                                        <input
                                                            type='file'
                                                            id='uploadImage'
                                                            onChange={handleFileChange}
                                                            className='hidden'
                                                            accept="image/*,video/*"
                                                        />
                                                    </form>
                                                </div>
                                            )}
                                        </div>

                                        <div className='h-full w-full flex gap-1'>
                                            <div id="allmessagesclick" style={{ display: 'none' }} onClick={() => { messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' }) }}>Click me</div>
                                            <input
                                                type='text'
                                                placeholder='Type here message...'
                                                value={newComment}
                                                onChange={(e) => setNewComment(e.target.value)}
                                                className='py-1 px-4 w-full h-full'
                                            />
                                            <button className='text-primary hover:text-secondary' onClick={handleSendComment}>
                                                <IoMdSend size={28} />
                                            </button>
                                        </div>
                                    </section>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}