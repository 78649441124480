// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css'; // Import your custom CSS file for styling

// export default function ShowSubAdmins() {
//     const [subAdmins, setSubAdmins] = useState([]);
//     const [filteredSubAdmins, setFilteredSubAdmins] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('UniqueId'); // Default search field
//     const { API_URL } = config;


//     const fetchSubAdmins = async () => {

//         const managerId = localStorage.getItem('admin'); // Fetch managerId from localStorage
//         fetch(`${API_URL}/api/subadmin/getSubAdmin`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ managerId }) // Send managerId in the request body
//         })
//         .then(res => res.json())
//         .then(data => {
//             console.log(data)
//             if (data.length > 0) { setSubAdmins(data); }
//             setLoading(false);
//         })
//         .catch(error => {
//             console.error("Error fetching sub-admins:", error);
//             setLoading(false);
//         });
//     }

//     useEffect(() => {
//        fetchSubAdmins();
//     }, []);

//     useEffect(() => {
//         // Filter sub-admins based on search term and field
//         if (subAdmins.length > 0) {
//             const filtered = subAdmins.filter(subAdmin =>
//                 subAdmin[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
//             );
//             setFilteredSubAdmins(filtered);
//         }
//     }, [searchTerm, searchField, subAdmins]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     const handleDelete = (id) => {
//         fetch(`${API_URL}/api/subadmin/DeleteSubAdmin`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({ id:id })
//         })
//         .then(res => res.json())
//         .then(data => {
//             console.log(data)
//             alert(data.message)
//             if (data.executed) {
//                 fetchSubAdmins();
//             }
//         })
//         .catch(error => {
//             console.error("Error deleting sub-admin:", error);
//         });
//     };
//     if (loading) {
//         return (
//             <div className='d-flex flex-column ' style={{ height: "100vh", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", padding: "20px" }}>
//             <h1 className='mt-3 mb-4 fw-bold'>SubAdmins</h1>

//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={handleSearchFieldChange} 
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="managerId">Manager ID</option>
//                     <option value="UniqueId">Unique ID</option>
//                     <option value="contact">Contact</option>
//                     <option value="department">Department</option>
//                     <option value="accountNumber">Account Number</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>

//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">Manager ID</th>
//                                 <th scope="col">Executive ID</th>
//                                 <th scope="col">Unique ID</th>
//                                 <th scope="col">Password</th>
//                                 <th scope="col">Contact</th>
//                                 <th scope="col">Department</th>
//                                 <th scope="col">Account Number</th>
//                                 <th scope="col">Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredSubAdmins.map(subAdmin => (
//                                 <tr key={subAdmin._id}>
//                                     <td>{subAdmin.managerId}</td>
//                                     <td>NukkadExecutive-{subAdmin._id}</td>
//                                     <td>{subAdmin.UniqueId}</td>
//                                     <td>{subAdmin.password}</td>
//                                     <td>{subAdmin.contact}</td>
//                                     <td>{subAdmin.department}</td>
//                                     <td>{subAdmin.accountNumber || 'N/A'}</td>
//                                     <td>
//                                         <button 
//                                             className="btn btn-danger btn-sm"
//                                             onClick={() => handleDelete(subAdmin._id)}
//                                         >
//                                             Delete
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import config from '../../config';

export default function ShowSubAdmins() {
    const [subAdmins, setSubAdmins] = useState([]);
    const [filteredSubAdmins, setFilteredSubAdmins] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('UniqueId');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSubAdmin, setSelectedSubAdmin] = useState(null);
    const { API_URL } = config;

    const fetchSubAdmins = async () => {
        const managerId = localStorage.getItem('admin');
        fetch(`${API_URL}/api/subadmin/getSubAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ managerId }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) setSubAdmins(data);
                setLoading(false);
                console.log(data)
            })
            .catch((error) => {
                console.error('Error fetching sub-admins:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchSubAdmins();
    }, []);

    useEffect(() => {
        if (subAdmins.length > 0) {
            const filtered = subAdmins.filter((subAdmin) =>
                subAdmin[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredSubAdmins(filtered);
        }
    }, [searchTerm, searchField, subAdmins]);

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchFieldChange = (event) => {
        setSearchField(event.target.value);
    };

    const handleDelete = (id) => {
        fetch(`${API_URL}/api/subadmin/DeleteSubAdmin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id }),
        })
            .then((res) => res.json())
            .then((data) => {
                alert(data.message);
                if (data.executed) fetchSubAdmins();
            })
            .catch((error) => {
                console.error('Error deleting sub-admin:', error);
            });
    };

    const openModal = (subAdmin) => {
        setSelectedSubAdmin(subAdmin);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedSubAdmin(null);
        setIsModalOpen(false);
    };

    const columns = [
        {
            name: 'Manager ID',
            selector: (row) => row.managerId,
            sortable: true,
            width: "250px"
        },
        {
            name: 'Unique ID',
            selector: (row) => row.UniqueId,
            sortable: true,
        },
        {
            name: 'Password',
            selector: (row) => row.password,
        },
        {
            name: 'Contact',
            selector: (row) => row.contact,
        },
        {
            name: 'Department',
            selector: (row) => row.department,
        },
        {
            name: 'Account Number',
            selector: (row) => row.accountNumber || 'N/A',
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div className="flex gap-2">
                    <button
                        className="text-white bg-blue-500 hover:bg-blue-600 px-2 py-1 rounded text-sm"
                        onClick={() => openModal(row)}
                    >
                        Details
                    </button>
                    <button
                        className="text-white bg-red-500 hover:bg-red-600 px-2 py-1 rounded text-sm"
                        onClick={() => handleDelete(row._id)}
                    >
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    const customStyles = {
        header: {
            style: {
                fontSize: '1.5rem',
                fontWeight: 'bold',
                textAlign: 'center',
                textTransform: 'uppercase',
                color: '#111827',
                backgroundColor: '#F3F4F6',
                borderBottom: '2px solid #D1D5DB',
                padding: '10px',
            },
        },
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: '600',
                color: '#374151',
                textTransform: 'uppercase',
                backgroundColor: '#E5E7EB',
                borderBottom: '2px solid #D1D5DB',
                padding: '12px',
                letterSpacing: '0.05em',
            },
        },
        cells: {
            style: {
                fontSize: '0.975rem',
                color: '#4B5563',
                backgroundColor: '#FFFFFF',
                borderBottom: '1px solid #E5E7EB',
                padding: '12px',
                whiteSpace: 'nowrap',
            },
        },
        rows: {
            style: {
                backgroundColor: '#FFFFFF',
                '&:not(:last-of-type)': {
                    borderBottom: '1px solid #E5E7EB',
                },
                '&:hover': {
                    backgroundColor: '#F9FAFB',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s ease',
                },
            },
        },
        pagination: {
            style: {
                fontSize: '0.875rem',
                backgroundColor: '#F3F4F6',
                color: '#6B7280',
                borderTop: '1px solid #D1D5DB',
                paddingTop: '10px',
                paddingBottom: '10px',
                justifyContent: 'end',
            },
            pageButtonsStyle: {
                color: '#2563EB',
                fill: '#2563EB',
                '&:hover:not(:disabled)': {
                    backgroundColor: '#EFF6FF',
                },
            },
        },
    };


    if (loading) {
        return (
            <div className="flex items-center justify-center h-[98vh]">
                <div className="spinner-grow text-green-500" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-6">Sub-Admins</h1>
            <div className="flex items-center justify-between mb-4 gap-7">
                <select
                    className="form-select border rounded-lg p-2 w-60"
                    value={searchField}
                    onChange={handleSearchFieldChange}
                >
                    <option value="managerId">Manager ID</option>
                    <option value="UniqueId">Unique ID</option>
                    <option value="contact">Contact</option>
                    <option value="department">Department</option>
                    <option value="accountNumber">Account Number</option>
                </select>
                <input
                    type="text"
                    placeholder={`Search by ${searchField}...`}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="border rounded-lg p-2 w-3/4"
                />
            </div>
            <DataTable
                columns={columns}
                data={filteredSubAdmins}
                pagination
                highlightOnHover
                striped
                customStyles={customStyles}
                className="shadow rounded-lg"
            />
            {isModalOpen && selectedSubAdmin && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-8 w-3/4 max-w-2xl">
                        <h2 className="text-2xl font-bold mb-6 text-gray-800">Sub-Admin Details</h2>
                        <div className="space-y-4">
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">Manager ID:</p>
                                <p className="text-gray-800">{selectedSubAdmin.managerId || 'N/A'}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">Unique ID:</p>
                                <p className="text-gray-800">{selectedSubAdmin.UniqueId || 'N/A'}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">Password:</p>
                                <p className="text-gray-800">{selectedSubAdmin.password || 'N/A'}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">Contact:</p>
                                <p className="text-gray-800">{selectedSubAdmin.contact || 'N/A'}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">Department:</p>
                                <p className="text-gray-800">{selectedSubAdmin.department || 'N/A'}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">Account Number:</p>
                                <p className="text-gray-800">{selectedSubAdmin.accountNumber || 'N/A'}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">ID:</p>
                                <p className="text-gray-800">{selectedSubAdmin._id || 'N/A'}</p>
                            </div>
                            <div className="flex justify-between items-center">
                                <p className="font-semibold text-gray-600">Options:</p>
                                <p className="text-gray-800">
                                    {selectedSubAdmin.options && selectedSubAdmin.options.length > 0
                                        ? selectedSubAdmin.options.join(', ')
                                        : 'N/A'}
                                </p>
                            </div>
                        </div>
                        <div className="mt-6 text-right">
                            <button
                                className="text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
