// import React from "react";
// import DetectDevice from "./DetectDevice";

// const DynamicComponent = ({ type, code }) => {
//   return (
//     <div className="p-5">
//       <h2 className="text-xl font-bold">Dynamic Invite</h2>
//       <p>
//         <span className="font-semibold">Type:</span> {type}
//       </p>
//       <p>
//         <span className="font-semibold">Code:</span> {code || "No code provided"}
//       </p>
//       <DetectDevice />
//     </div>
//   );
// };

// export default DynamicComponent;

import DetectDevice from "./DetectDevice";

const DynamicComponent = () => {
  return (
    <div className="text-center p-5">
      <h1 className="text-2xl font-bold">Redirecting...</h1>
      <p className="mt-3">
        <DetectDevice/>
      </p>
    </div>
  );
};

export default DynamicComponent;