// import React, { useEffect, useState } from 'react';
// import config from '../../config';

// export default function Wallet() {
//     const [wallets, setWallets] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const { API_URL } = config;

//     useEffect(() => {
//         fetch(`${API_URL}/api/wallet/wallets`)
//             .then(res => res.json())
//             .then(data => {
//                 setWallets(data.wallets);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching wallets:", error);
//                 setLoading(false);
//             });
//     }, []);

//     if (loading) {
//         return (
//             <div className='d-flex flex-column ' style={{ height: "98vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", maxWidth: "100vw", padding: "20px" }}>
//             <h1 className='mt-3 mb-4 fw-bold'>Wallets</h1>
            
//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", maxWidth: "1200px", height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">UID</th>
//                                 <th scope="col">Amount</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {wallets.map(wallet => (
//                                 <tr key={wallet._id}>
//                                     <td>{wallet.uid}</td>
//                                     <td>{wallet.amount}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import config from '../../config';

export default function Wallet() {
    const [wallets, setWallets] = useState([]);
    const [loading, setLoading] = useState(true);
    const { API_URL } = config;

    useEffect(() => {
        fetch(`${API_URL}/api/wallet/wallets`)
            .then(res => res.json())
            .then(data => {
                setWallets(data.wallets);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching wallets:', error);
                setLoading(false);
            });
    }, []);

    const columns = [
        {
            name: 'UID',
            selector: (row) => row.uid,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: (row) => row.amount,
            sortable: true,
        },
    ];

    if (loading) {
        return (
            <div className="flex flex-col h-[98vh] w-full justify-center items-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-green-500 border-solid"></div>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#1f2937',
                color: '#ffffff', 
                fontWeight: 'bold',
                fontSize: '14px',
                padding: '10px 15px',
                borderBottom: '1px solid #e5e7eb', 
            },
        },
        cells: {
            style: {
                padding: '10px 15px',
                fontSize: '14px',
                color: '#4b5563', 
            },
        },
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#f3f4f6', 
                    cursor: 'pointer',
                },
                borderBottom: '1px solid #e5e7eb', 
            },
        },
        pagination: {
            style: {
                backgroundColor: '#f9fafb', 
                color: '#374151', 
                fontSize: '12px',
            },
            pageButtonsStyle: {
                borderRadius: '5px',
                height: '30px',
                width: '30px',
                padding: '5px',
                margin: '0 5px',
                backgroundColor: '#e5e7eb',
                color: '#374151', 
                '&:hover': {
                    backgroundColor: '#1f2937', 
                    color: '#ffffff',
                },
            },
        },
    };
    
    return (
        <div className="px-8">
            <h1 className="mt-3 mb-4 font-bold text-2xl">Wallets</h1>
            <div className="shadow-lg bg-white flex flex-col w-full max-w-5xl overflow-y-auto rounded-xl overflow-hidden">
                <DataTable
                    columns={columns}
                    data={wallets}
                    pagination
                    highlightOnHover
                    pointerOnHover
                    customStyles={customStyles}
                />
            </div>
        </div>
    );
}
