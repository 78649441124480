// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css';

// export default function RiderApproval() {
//     const [deliveryBoys, setDeliveryBoys] = useState([]);
//     const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('name');
//     const { API_URL } = config;
//     const [selectedBoy, setSelectedBoy] = useState({});
//     const [pic, setPic] = useState("");

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//             .then(res => res.json())
//             .then(data => {
//                 const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                 setDeliveryBoys(unverifiedBoys);
//                 setFilteredDeliveryBoys(unverifiedBoys);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching unverified delivery boys:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         if (selectedBoy) {
//             const images = [
//                 selectedBoy.idProofPic,
//                 selectedBoy.pancardPic,
//                 selectedBoy.drivingLicensePic
//             ];
//             images.forEach((src) => {
//                 if (src) {
//                     const img = new Image();
//                     img.src = src;
//                 }
//             });

//             setPic(selectedBoy.idProofPic);
//         }
//     }, [selectedBoy]);

//     useEffect(() => {
//         const filtered = deliveryBoys.filter(boy =>
//             boy[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredDeliveryBoys(filtered);
//     }, [searchTerm, searchField, deliveryBoys]);

//     const handleVerifyBoy = (id) => {
//         try {
//             fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ id: id, updateData: { status: 'verified' } }),
//             })
//             .then(res => res.json())
//             .then(data => {
//                 // console.log(data)
//                 if (data.executed) {
//                     alert('Delivery boy verified successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                             setDeliveryBoys(unverifiedBoys);
//                             setFilteredDeliveryBoys(unverifiedBoys);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified delivery boys:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify delivery boy');
//                 }
//             })
//         } catch (error) {
//             alert('Failed to verify delivery boy', error);
//         }
//     };

//     const handleInvalidBoy = (id) => {
//         try {
//             fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ _id: id, updateData: { status: 'Invalid' } }),
//             })
//             .then(res => res.json())
//             .then(data => {
//                 if (data.executed) {
//                     alert('Delivery boy marked as invalid successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                             setDeliveryBoys(unverifiedBoys);
//                             setFilteredDeliveryBoys(unverifiedBoys);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified delivery boys:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to mark delivery boy as invalid');
//                 }
//             })
//         } catch (error) {
//             alert('Failed to mark delivery boy as invalid', error);
//         }
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column bg-white' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column' style={{ height: "100vh", maxWidth: "100vw", padding: "20px", justifyContent:"center" , alignItems:"center" }}>
//             <h1 className='mt-3 mb-4 fw-bold'>Rider Approval</h1>
            
//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={(e) => setSearchField(e.target.value)}
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="name">Name</option>
//                     <option value="email">Email</option>
//                     <option value="city">City</option>
//                     <option value="contact">Contact</option>
//                     <option value="firstName">First Name</option>
//                     <option value="lastName">Last Name</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>
            
//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">ID</th>
//                                 <th scope="col">Name</th>
//                                 <th scope="col">Email</th>
//                                 <th scope="col">Contact</th>
//                                 <th scope="col">City</th>
//                                 <th scope="col">Gender</th>
//                                 <th scope="col">Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredDeliveryBoys.map(boy => (
//                                 <tr key={boy._id}>
//                                     <td>{boy._id}</td>
//                                     <td>{boy.name}</td>
//                                     <td>{boy.email}</td>
//                                     <td>{boy.contact}</td>
//                                     <td>{boy.city}</td>
//                                     <td>{boy.gender}</td>
//                                     <td>
//                                         <button 
//                                             type="button" 
//                                             data-bs-toggle="modal" 
//                                             data-bs-target="#exampleModal"
//                                             onClick={() => {
//                                                 setPic("");
//                                                 setSelectedBoy(boy);
//                                             }}
//                                             className="btn btn-primary btn-sm"
//                                         >
//                                             Verify
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//             {InfoModal(selectedBoy, pic, setPic, handleVerifyBoy, handleInvalidBoy)}
//         </div>
//     );
// }

// function InfoModal(data, pic, setPic, handleVerifyBoy, handleInvalidBoy) {
//     return (
//         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h1 className="modal-title fs-5" id="exampleModalLabel">Verify {data.name}</h1>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                         <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
//                             <div className='overflow-auto d-flex flex-column gap-2'>
//                                 <button type="button" className="btn btn-primary btn-sm" onClick={() => setPic(data.idProofPic)}>View ID Proof</button>
//                                 <button type="button" className="btn btn-primary btn-sm" onClick={() => setPic(data.pancardPic)}>View PAN Card</button>
//                                 <button type="button" className="btn btn-primary btn-sm" onClick={() => setPic(data.drivingLicensePic)}>View Driving License</button>
//                             </div>
//                             <div>
//                                 {pic ? (
//                                     <img src={pic} height={"300px"} loading='lazy' alt="Selected" onLoad={() => console.log('Image loaded')} />
//                                 ) : (
//                                     <div>Loading...</div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleInvalidBoy(data._id)}>Invalid</button>
//                         <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleVerifyBoy(data._id)}>Verify</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }



// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css';

// export default function RiderApproval() {
//     const [deliveryBoys, setDeliveryBoys] = useState([]);
//     const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('name');
//     const { API_URL } = config;
//     const [selectedBoy, setSelectedBoy] = useState({});
//     const [pic, setPic] = useState("");

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//             .then(res => res.json())
//             .then(data => {
//                 const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                 setDeliveryBoys(unverifiedBoys);
//                 setFilteredDeliveryBoys(unverifiedBoys);
//                 console.log(unverifiedBoys)
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching unverified delivery boys:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         if (selectedBoy) {
//             const images = [
//                 selectedBoy.idProofPic,
//                 selectedBoy.pancardPic,
//                 selectedBoy.drivingLicensePic
//             ];
//             images.forEach((src) => {
//                 if (src) {
//                     const img = new Image();
//                     img.src = src;
//                 }
//             });

//             setPic(selectedBoy.idProofPic);
//         }
//     }, [selectedBoy]);

//     useEffect(() => {
//         const filtered = deliveryBoys.filter(boy =>
//             boy[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredDeliveryBoys(filtered);
//     }, [searchTerm, searchField, deliveryBoys]);

//     const handleVerifyBoy = (id) => {
//         try {
//             fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ id: id, updateData: { status: 'verified' } }),
//             })
//             .then(res => res.json())
//             .then(data => {
//                 // console.log(data)
//                 if (data.executed) {
//                     alert('Delivery boy verified successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                             setDeliveryBoys(unverifiedBoys);
//                             setFilteredDeliveryBoys(unverifiedBoys);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified delivery boys:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify delivery boy');
//                 }
//             })
//         } catch (error) {
//             alert('Failed to verify delivery boy', error);
//         }
//     };

//     const handleInvalidBoy = (id) => {
//         try {
//             fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ _id: id, updateData: { status: 'Invalid' } }),
//             })
//             .then(res => res.json())
//             .then(data => {
//                 if (data.executed) {
//                     alert('Delivery boy marked as invalid successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                             setDeliveryBoys(unverifiedBoys);
//                             setFilteredDeliveryBoys(unverifiedBoys);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified delivery boys:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to mark delivery boy as invalid');
//                 }
//             })
//         } catch (error) {
//             alert('Failed to mark delivery boy as invalid', error);
//         }
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column bg-white' style={{ height: "98vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='p-8'>
//             {/* <div className='d-flex flex-column' style={{ height: "100vh", maxWidth: "100vw", padding: "20px", justifyContent:"center" , alignItems:"center" }}></div> */}
//             <h1 className="mt-3 mb-4 font-bold text-xl">Nukkad Approval</h1>
            
//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={(e) => setSearchField(e.target.value)}
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="name">Name</option>
//                     <option value="email">Email</option>
//                     <option value="city">City</option>
//                     <option value="contact">Contact</option>
//                     <option value="firstName">First Name</option>
//                     <option value="lastName">Last Name</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>
            
//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">ID</th>
//                                 <th scope="col">Name</th>
//                                 <th scope="col">Email</th>
//                                 <th scope="col">Contact</th>
//                                 <th scope="col">City</th>
//                                 <th scope="col">Gender</th>
//                                 <th scope="col">Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredDeliveryBoys.map(boy => (
//                                 <tr key={boy._id}>
//                                     <td>{boy._id}</td>
//                                     <td>{boy.name}</td>
//                                     <td>{boy.email}</td>
//                                     <td>{boy.contact}</td>
//                                     <td>{boy.city}</td>
//                                     <td>{boy.gender}</td>
//                                     <td>
//                                         <button 
//                                             type="button" 
//                                             data-bs-toggle="modal" 
//                                             data-bs-target="#exampleModal"
//                                             onClick={() => {
//                                                 setPic("");
//                                                 setSelectedBoy(boy);
//                                             }}
//                                             className="btn btn-primary btn-sm"
//                                         >
//                                             Verify
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//             {InfoModal(selectedBoy, pic, setPic, handleVerifyBoy, handleInvalidBoy)}
//         </div>
//     );
// }

// function InfoModal(data, pic, setPic, handleVerifyBoy, handleInvalidBoy) {
//     return (
//         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h1 className="modal-title fs-5" id="exampleModalLabel">Verify {data.name}</h1>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                         <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
//                             <div className='overflow-auto d-flex flex-column gap-2'>
//                                 <button type="button" className="btn btn-primary btn-sm" onClick={() => setPic(data.idProofPic)}>View ID Proof</button>
//                                 <button type="button" className="btn btn-primary btn-sm" onClick={() => setPic(data.pancardPic)}>View PAN Card</button>
//                                 <button type="button" className="btn btn-primary btn-sm" onClick={() => setPic(data.drivingLicensePic)}>View Driving License</button>
//                             </div>
//                             <div>
//                                 {pic ? (
//                                     <img src={pic} height={"300px"} loading='lazy' alt="Selected" onLoad={() => console.log('Image loaded')} />
//                                 ) : (
//                                     <div>Loading...</div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleInvalidBoy(data._id)}>Invalid</button>
//                         <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => handleVerifyBoy(data._id)}>Verify</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import DataTable from 'react-data-table-component';
// import config from '../../config';

// export default function RiderApproval() {
//     const [deliveryBoys, setDeliveryBoys] = useState([]);
//     const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('name');
//     const { API_URL } = config;
//     const [selectedBoy, setSelectedBoy] = useState({});
//     const [pic, setPic] = useState("");

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//             .then(res => res.json())
//             .then(data => {
//                 const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                 setDeliveryBoys(unverifiedBoys);
//                 setFilteredDeliveryBoys(unverifiedBoys);
//                  console.log(unverifiedBoys)
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching unverified delivery boys:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         const filtered = deliveryBoys.filter(boy =>
//             boy[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredDeliveryBoys(filtered);
//     }, [searchTerm, searchField, deliveryBoys]);

//     const handleVerifyBoy = async (id) => {
//         try {
//             const response = await fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ id, updateData: { status: 'verified' } }),
//             });
//             const data = await response.json();
//             if (data.executed) {
//                 alert('Delivery boy verified successfully');
//                 refreshDeliveryBoys();
//             } else {
//                 alert('Failed to verify delivery boy');
//             }
//         } catch (error) {
//             console.error('Error verifying delivery boy:', error);
//         }
//     };

//     const handleInvalidBoy = async (id) => {
//         try {
//             const response = await fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify({ id, updateData: { status: 'Invalid' } }),
//             });
//             const data = await response.json();
//             if (data.executed) {
//                 alert('Delivery boy marked as invalid');
//                 refreshDeliveryBoys();
//             } else {
//                 alert('Failed to mark delivery boy as invalid');
//             }
//         } catch (error) {
//             console.error('Error marking delivery boy as invalid:', error);
//         }
//     };

//     const refreshDeliveryBoys = () => {
//         setLoading(true);
//         fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//             .then(res => res.json())
//             .then(data => {
//                 const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
//                 setDeliveryBoys(unverifiedBoys);
//                 setFilteredDeliveryBoys(unverifiedBoys);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching unverified delivery boys:", error);
//                 setLoading(false);
//             });
//     };

//     const columns = [
//         // { name: 'ID', selector: row => row._id, sortable: true },
//         { name: 'Name', selector: row => row.name, sortable: true },
//         { name: 'Email', selector: row => row.email },
//         { name: 'Contact', selector: row => row.contact },
//         { name: 'City', selector: row => row.city },
//         // { name: 'Gender', selector: row => row.gender },
//         {
//             name: 'Actions',
//             cell: row => (
//                 <button
//                     className="bg-blue-500 text-white px-3 py-1 rounded shadow hover:bg-blue-600"
//                     data-bs-toggle="modal"
//                     data-bs-target="#exampleModal"
//                     onClick={() => {
//                         setPic("");
//                         setSelectedBoy(row);
//                     }}
//                 >
//                     Verify
//                 </button>
//             ),
//         },
//     ];

//     if (loading) {
//         return (
//             <div className="flex justify-center items-center h-screen bg-gray-100">
//                 <div className="spinner-border text-blue-500" role="status">
//                     <span className="sr-only">Loading...</span>
//                 </div>
//             </div>
//         );
//     }


//     const customStyles = {
//         header: {
//             style: {
//                 fontSize: '1.25rem',
//                 fontWeight: '700',
//                 textTransform: 'uppercase',
//                 color: '#374151',
//                 backgroundColor: '#F9FAFB',
//                 borderBottom: '2px solid #E5E7EB',
//             },
//         },
//         headCells: {
//             style: {
//                 fontSize: '1rem',
//                 fontWeight: '600',
//                 color: '#1F2937',
//                 backgroundColor: '#F3F4F6',
//                 borderBottom: '1px solid #D1D5DB',
//                 padding: '10px',
//             },
//         },
//         cells: {
//             style: {
//                 fontSize: '0.975rem',
//                 color: '#4B5563',
//                 borderBottom: '1px solid #E5E7EB',
//                 padding: '10px',
//             },
//         },
//         rows: {
//             style: {
//                 '&:hover': {
//                     backgroundColor: '#F9FAFB',
//                     cursor: 'pointer',
//                 },
//             },
//         },
//         pagination: {
//             style: {
//                 fontSize: '0.875rem',
//                 backgroundColor: '#F9FAFB',
//                 color: '#6B7280',
//                 borderTop: '1px solid #E5E7EB',
//                 paddingTop: '10px',
//                 paddingBottom: '10px',
//                 justifyContent: 'end',
//             },
//         },
//     };

//     return (
//         <div className="p-6">
//             <h1 className="text-2xl font-bold mb-4">Rider Approval</h1>

//             <div className="flex items-center mb-4 space-x-4">
//                 <select
//                     className="border border-gray-300 rounded px-4 py-2"
//                     value={searchField}
//                     onChange={(e) => setSearchField(e.target.value)}
//                 >
//                     <option value="name">Name</option>
//                     <option value="email">Email</option>
//                     <option value="city">City</option>
//                     <option value="contact">Contact</option>
//                 </select>
//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="border border-gray-300 rounded px-4 py-2 flex-grow"
//                 />
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredDeliveryBoys}
//                 pagination
//                 highlightOnHover
//                 customStyles={customStyles}
//                 className="shadow-md rounded-lg border border-gray-200"
//             />

//             {selectedBoy && InfoModal(selectedBoy, pic, setPic, handleVerifyBoy, handleInvalidBoy)}
//         </div>
//     );
// }

// function InfoModal(data, pic, setPic, handleVerifyBoy, handleInvalidBoy) {
//     return (
//         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-lg">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title">Verify {data.name}</h5>
//                         <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
//                             <span aria-hidden="true">&times;</span>
//                         </button>
//                     </div>
//                     <div className="modal-body flex flex-col md:flex-row gap-4">
//                         <div className="flex flex-col space-y-2">
//                             <button className="bg-gray-200 px-4 py-2 rounded" onClick={() => setPic(data.idProofPic)}>View ID Proof</button>
//                             <button className="bg-gray-200 px-4 py-2 rounded" onClick={() => setPic(data.pancardPic)}>View PAN Card</button>
//                             <button className="bg-gray-200 px-4 py-2 rounded" onClick={() => setPic(data.drivingLicensePic)}>View Driving License</button>
//                         </div>
//                         <div>
//                             {pic ? (
//                                 <img src={pic} alt="Selected Proof" className="rounded border" />
//                             ) : (
//                                 <div>Loading...</div>
//                             )}
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button className="bg-red-500 text-white px-4 py-2 rounded" data-bs-dismiss="modal" onClick={() => handleInvalidBoy(data._id)}>Mark Invalid</button>
//                         <button className="bg-blue-500 text-white px-4 py-2 rounded" data-bs-dismiss="modal" onClick={() => handleVerifyBoy(data._id)}>Verify</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import config from '../../config';

export default function RiderApproval() {
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('name');
    const { API_URL } = config;
    const [selectedBoy, setSelectedBoy] = useState({});
    const [pic, setPic] = useState("");
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    useEffect(() => {
        fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
            .then(res => res.json())
            .then(data => {
                const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
                setDeliveryBoys(unverifiedBoys);
                setFilteredDeliveryBoys(unverifiedBoys);
                console.log(unverifiedBoys);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching unverified delivery boys:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const filtered = deliveryBoys.filter(boy =>
            boy[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDeliveryBoys(filtered);
    }, [searchTerm, searchField, deliveryBoys]);

    const handleVerifyBoy = async (id) => {
        try {
            const response = await fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, updateData: { status: 'verified' } }),
            });
            const data = await response.json();
            if (data.executed) {
                alert('Delivery boy verified successfully');
                refreshDeliveryBoys();
            } else {
                alert('Failed to verify delivery boy');
            }
        } catch (error) {
            console.error('Error verifying delivery boy:', error);
        }
    };

    const handleInvalidBoy = async (id) => {
        try {
            const response = await fetch(`${API_URL}/api/auth/updateDeliveryBoyById`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, updateData: { status: 'Invalid' } }),
            });
            const data = await response.json();
            if (data.executed) {
                alert('Delivery boy marked as invalid');
                refreshDeliveryBoys();
            } else {
                alert('Failed to mark delivery boy as invalid');
            }
        } catch (error) {
            console.error('Error marking delivery boy as invalid:', error);
        }
    };

    const refreshDeliveryBoys = () => {
        setLoading(true);
        fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
            .then(res => res.json())
            .then(data => {
                const unverifiedBoys = data.deliveryBoys.filter(boy => boy.status === 'unverified');
                setDeliveryBoys(unverifiedBoys);
                setFilteredDeliveryBoys(unverifiedBoys);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error refreshing delivery boys:", error);
                setLoading(false);
            });
    };

    const columns = [
        { name: 'Name', selector: row => row.name, sortable: true },
        { name: 'Email', selector: row => row.email },
        { name: 'Contact', selector: row => row.contact },
        { name: 'City', selector: row => row.city },
        {
            name: 'Actions',
            cell: row => (
                <div className="flex space-x-2">
                    <button
                        className="bg-blue-500 text-white px-3 py-2 rounded shadow hover:bg-blue-600"
                        data-bs-toggle="modal"
                        data-bs-target="#verifyModal"
                        onClick={() => {
                            setPic("");
                            setSelectedBoy(row);
                        }}
                    >
                        Verify
                    </button>
                    <button
                        className="bg-green-500 text-white px-3 py-2 rounded shadow hover:bg-green-600"
                        onClick={() => {
                            setSelectedBoy(row);
                            setDetailsModalOpen(true);
                        }}
                    >
                        Details
                    </button>
                </div>
            ),
        },
    ];

    if (loading) {
        return (
            <div className="flex justify-center items-center h-[98vh] bg-gray-100">
                <div className="spinner-border text-blue-500" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    const customStyles = {
        header: {
            style: {
                fontSize: '1.25rem',
                fontWeight: '700',
                textTransform: 'uppercase',
                color: '#374151',
                backgroundColor: '#F9FAFB',
                borderBottom: '2px solid #E5E7EB',
            },
        },
        headCells: {
            style: {
                fontSize: '1rem',
                fontWeight: '600',
                color: '#1F2937',
                backgroundColor: '#F3F4F6',
                borderBottom: '1px solid #D1D5DB',
                padding: '10px',
            },
        },
        cells: {
            style: {
                fontSize: '0.975rem',
                color: '#4B5563',
                borderBottom: '1px solid #E5E7EB',
                padding: '10px',
            },
        },
        rows: {
            style: {
                '&:hover': {
                    backgroundColor: '#F9FAFB',
                    cursor: 'pointer',
                },
            },
        },
        pagination: {
            style: {
                fontSize: '0.875rem',
                backgroundColor: '#F9FAFB',
                color: '#6B7280',
                borderTop: '1px solid #E5E7EB',
                paddingTop: '10px',
                paddingBottom: '10px',
                justifyContent: 'end',
            },
        },
    };

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Rider Approval</h1>

            <div className="flex items-center mb-4 space-x-4">
                <select
                    className="border border-gray-300 rounded px-4 py-2"
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                >
                    <option value="name">Name</option>
                    <option value="email">Email</option>
                    <option value="city">City</option>
                    <option value="contact">Contact</option>
                </select>
                <input
                    type="text"
                    placeholder={`Search by ${searchField}`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-gray-300 px-4 py-2 flex-grow rounded-lg"
                />
            </div>

            <DataTable
                columns={columns}
                data={filteredDeliveryBoys}
                pagination
                highlightOnHover
                customStyles={customStyles}
                className="shadow-md rounded-lg border border-gray-200"
            />

            {selectedBoy && InfoModal(selectedBoy, pic, setPic, handleVerifyBoy, handleInvalidBoy)}

            {detailsModalOpen && (
                <DetailsModal deliveryBoy={selectedBoy} onClose={() => setDetailsModalOpen(false)} />
            )}
        </div>
    );
}

function InfoModal(data, pic, setPic, handleVerifyBoy, handleInvalidBoy) {
    return (
        <div className="modal fade" id="verifyModal" tabIndex="-1" aria-labelledby="verifyModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-3xl font-semibold text-gray-800">Verify {data.name}</h5>
                        {/* <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button> */}
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6 text-black"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="modal-body flex flex-col md:flex-row gap-4">
                        <div className="flex flex-col space-y-2">
                            <button className="bg-blue-500 px-4 py-2 rounded" onClick={() => setPic(data.idProofPic)}>View ID Proof</button>
                            <button className="bg-blue-500 px-4 py-2 rounded" onClick={() => setPic(data.pancardPic)}>View PAN Card</button>
                            <button className="bg-blue-500 px-4 py-2 rounded" onClick={() => setPic(data.drivingLicensePic)}>View Driving License</button>
                        </div>
                        <div>
                            {pic ? (
                                <img src={pic} alt="Selected Proof" className="rounded border h-[450px] w-[800px] object-contain" />
                            ) : (
                                <div>Click to check ID</div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="bg-red-500 text-white px-4 py-2 rounded" data-bs-dismiss="modal" onClick={() => handleInvalidBoy(data._id)}>Mark Invalid</button>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded" data-bs-dismiss="modal" onClick={() => handleVerifyBoy(data._id)}>Verify</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DetailsModal({ deliveryBoy, onClose }) {
    return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-4xl overflow-y-auto h-[90vh]">
          <div className="border-b pb-4 mb-6">
            <h2 className="text-3xl font-semibold text-gray-800">
              Delivery Partner: {deliveryBoy.name}
            </h2>
          </div>
  
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-semibold text-gray-700 mb-4">
                Personal Details
              </h3>
              <div className="space-y-2 text-gray-600">
                <p>
                  <strong className="font-medium">Email:</strong> {deliveryBoy.email}
                </p>
                <p>
                  <strong className="font-medium">Contact:</strong> {deliveryBoy.contact}
                </p>
                <p>
                  <strong className="font-medium">WhatsApp:</strong>{" "}
                  {deliveryBoy.whatsappContact}
                </p>
                <p>
                  <strong className="font-medium">City:</strong> {deliveryBoy.city}
                </p>
                <p>
                  <strong className="font-medium">Gender:</strong> {deliveryBoy.gender}
                </p>
                <p>
                  <strong className="font-medium">Date of Birth:</strong>{" "}
                  {new Date(deliveryBoy.DOB).toLocaleDateString()}
                </p>
              </div>
            </div>
  
            <div>
              <h3 className="text-xl font-semibold text-gray-700 mb-4">
                Bank Details
              </h3>
              <div className="space-y-2 text-gray-600">
                <p>
                  <strong className="font-medium">Account Number:</strong>{" "}
                  {deliveryBoy.bankDetails.accountNumber}
                </p>
                <p>
                  <strong className="font-medium">Branch Code:</strong>{" "}
                  {deliveryBoy.bankDetails.branchCode}
                </p>
                <p>
                  <strong className="font-medium">IFSC Code:</strong>{" "}
                  {deliveryBoy.bankDetails.IFSCCode}
                </p>
              </div>
            </div>
  
            <div className="col-span-2">
              <h3 className="text-xl font-semibold text-gray-700 mb-4">
                Work Details
              </h3>
              {deliveryBoy.workPreference?.length > 0 ? (
                <div className="space-y-4">
                  {deliveryBoy.workPreference.map((work, index) => (
                    <div
                      key={index}
                      className="p-4 border rounded-lg bg-gray-50 text-gray-600"
                    >
                      <p>
                        <strong className="font-medium">Location:</strong>{" "}
                        {work.locationName}
                      </p>
                      <p>
                        <strong className="font-medium">Description:</strong>{" "}
                        {work.description}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500">No work preferences available.</p>
              )}
            </div>
  
            <div className="col-span-2">
              <h3 className="text-xl font-semibold text-gray-700 mb-4">
                Uploaded Documents
              </h3>
              <div className="grid grid-cols-3 gap-4">
                <div className="text-center">
                  <p className="text-gray-600 font-medium">ID Proof</p>
                  <a
                    href={deliveryBoy.idProofPic}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block mt-2"
                  >
                    <img
                      src={deliveryBoy.idProofPic}
                      alt="ID Proof"
                      className="w-full h-32 object-contain rounded-lg border"
                    />
                    <button className="mt-2 text-blue-600 underline">
                      View Image
                    </button>
                  </a>
                </div>
  
                <div className="text-center">
                  <p className="text-gray-600 font-medium">Driving License</p>
                  <a
                    href={deliveryBoy.drivingLicensePic}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block mt-2"
                  >
                    <img
                      src={deliveryBoy.drivingLicensePic}
                      alt="Driving License"
                      className="w-full h-32 object-contain rounded-lg border"
                    />
                    <button className="mt-2 text-blue-600 underline">
                      View Image
                    </button>
                  </a>
                </div>
  
                <div className="text-center">
                  <p className="text-gray-600 font-medium">PAN Card</p>
                  <a
                    href={deliveryBoy.pancardPic}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block mt-2"
                  >
                    <img
                      src={deliveryBoy.pancardPic}
                      alt="PAN Card"
                      className="w-full h-32 object-contain rounded-lg border"
                    />
                    <button className="mt-2 text-blue-600 underline">
                      View Image
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
  
          <div className="flex justify-end mt-8">
            <button
              className="bg-red-600 text-white font-medium px-6 py-2 rounded-md hover:bg-red-700 transition"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }
  
  