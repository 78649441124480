// import React, { useEffect, useState } from 'react';
// import config from '../../config';

// export default function Users() {
//     const [users, setUsers] = useState([]);
//     const [filteredUsers, setFilteredUsers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('username');
//     const { API_URL } = config;

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/fetchAllUsers`)
//             .then(res => res.json())
//             .then(data => {
//                 setUsers(data.users);
//                 console.log(data.users);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching users:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         // Filter users based on search term and field
//         const filtered = users.filter(user =>
//             user[searchField] &&
//             user[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredUsers(filtered);
//     }, [searchTerm, searchField, users]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column ' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", maxWidth: "100vw", padding: "20px" }}>
//             <h1 className='mt-3 mb-4 fw-bold'>Users</h1>

//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%", maxWidth: "1200px" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={handleSearchFieldChange} 
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="username">Username</option>
//                     <option value="email">Email</option>
//                     <option value="contact">Contact</option>
//                     <option value="gender">Gender</option>
//                     <option value="area">referredby</option>

//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>

//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", maxWidth: "1200px", height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">Username</th>
//                                 <th scope="col">Email</th>
//                                 <th scope="col">Contact</th>
//                                 <th scope="col">Gender</th>
//                                 <th scope="col">referredby</th>
//                                 <th scope="col">reference</th>

//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredUsers.map(user => (
//                                 <tr key={user._id}>
//                                     <td>{user.username}</td>
//                                     <td>{user.email}</td>
//                                     <td>{user.contact}</td>
//                                     <td>{user.gender}</td>
//                                     <td>{user.referredby.referredby}</td>
//                                     <td>{user.referredby.reference}</td>

//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useState } from 'react';

// const UserTable = () => {
//   const users = [
//     {
//       id: 1,
//       name: 'Ajay Tiwari',
//       email: 'ajay@gmail.com',
//       area: 'Midtown',
//       contactNumber: '4267372783',
//       personalInfo: {
//         contact: '(201) 555-0124',
//         gender: 'Male',
//         dob: '1 Jan, 1999',
//         memberSince: '3 March, 2023',
//       },
//       shippingInfo: {
//         address: 'Vijay Nagar, Indore',
//         totalOrders: 150,
//         completed: 140,
//         canceled: 10,
//       },
//     },
//     {
//         id: 2,
//         name: 'Ajay Tiwari',
//         email: 'ajay@gmail.com',
//         area: 'Midtown',
//         contactNumber: '4267372783',
//         personalInfo: {
//           contact: '(201) 555-0124',
//           gender: 'Male',
//           dob: '1 Jan, 1999',
//           memberSince: '3 March, 2023',
//         },
//         shippingInfo: {
//           address: 'Vijay Nagar, Indore',
//           totalOrders: 150,
//           completed: 140,
//           canceled: 10,
//         },
//       },
//       {
//         id: 3,
//         name: 'Ajay Tiwari',
//         email: 'ajay@gmail.com',
//         area: 'Midtown',
//         contactNumber: '4267372783',
//         personalInfo: {
//           contact: '(201) 555-0124',
//           gender: 'Male',
//           dob: '1 Jan, 1999',
//           memberSince: '3 March, 2023',
//         },
//         shippingInfo: {
//           address: 'Vijay Nagar, Indore',
//           totalOrders: 150,
//           completed: 140,
//           canceled: 10,
//         },
//       },
//   ];

//   const [expandedRows, setExpandedRows] = useState({});

//   const toggleRow = (id) => {
//     setExpandedRows((prevState) => ({
//       ...prevState,
//       [id]: !prevState[id],
//     }));
//   };

//   return (
//     <div style={{ padding: '20px' }}>
//       <table style={{ width: '100%', borderCollapse: 'collapse' }}>
//         <thead>
//           <tr>
//             <th style={styles.headerCell}>User Name</th>
//             <th style={styles.headerCell}>Email</th>
//             <th style={styles.headerCell}>Area</th>
//             <th style={styles.headerCell}>Contact Number</th>
//             <th style={styles.headerCell}>More Details</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map((user) => (
//             <React.Fragment key={user.id}>
//               <tr>
//                 <td style={styles.cell}>{user.name}</td>
//                 <td style={styles.cell}>{user.email}</td>
//                 <td style={styles.cell}>{user.area}</td>
//                 <td style={styles.cell}>{user.contactNumber}</td>
//                 <td style={styles.cell}>
//                   <button onClick={() => toggleRow(user.id)} style={styles.button}>
//                     {expandedRows[user.id] ? '▲' : '▼'}
//                   </button>
//                 </td>
//               </tr>
//               {expandedRows[user.id] && (
//                 <tr>
//                   <td colSpan="5" style={styles.expandedRow}>
//                     <div style={styles.detailsContainer}>
//                       <div style={styles.infoSection}>
//                         <h4>Personal Information</h4>
//                         <p>Contact Number: {user.personalInfo.contact}</p>
//                         <p>Gender: {user.personalInfo.gender}</p>
//                         <p>Date of Birth: {user.personalInfo.dob}</p>
//                         <p>Member Since: {user.personalInfo.memberSince}</p>
//                       </div>
//                       <div style={styles.infoSection}>
//                         <h4>Shipping Address</h4>
//                         <p>Address: {user.shippingInfo.address}</p>
//                         <p>Total Orders: {user.shippingInfo.totalOrders}</p>
//                         <p>Completed: {user.shippingInfo.completed}</p>
//                         <p>Canceled: {user.shippingInfo.canceled}</p>
//                       </div>
//                     </div>
//                   </td>
//                 </tr>
//               )}
//             </React.Fragment>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// // Inline styles
// const styles = {
//   headerCell: {
//     padding: '12px',
//     borderBottom: '1px solid #ddd',
//     textAlign: 'left',
//     fontWeight: 'bold',
//   },
//   cell: {
//     padding: '12px',
//     borderBottom: '1px solid #ddd',
//     textAlign: 'left',
//   },
//   button: {
//     background: 'none',
//     border: 'none',
//     cursor: 'pointer',
//     fontSize: '18px',
//   },
//   expandedRow: {
//     backgroundColor: '#f9f9f9',
//     padding: '20px',
//   },
//   detailsContainer: {
//     display: 'flex',
//     justifyContent: 'space-between',
//   },
//   infoSection: {
//     marginRight: '20px',
//   },
// };

// export default UserTable;


// import React, { useEffect, useState } from 'react';
// import DataTable from 'react-data-table-component';
// import config from '../../config';

// export default function Users() {
//     const [users, setUsers] = useState([]);
//     const [filteredUsers, setFilteredUsers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('username');
//     const { API_URL } = config;

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/fetchAllUsers`)
//             .then(res => res.json())
//             .then(data => {
//                 setUsers(data.users);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching users:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         const filtered = users.filter(user =>
//             user[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredUsers(filtered);
//     }, [searchTerm, searchField, users]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     const columns = [
//         { name: 'Username', selector: row => row.username, sortable: true },
//         { name: 'Email', selector: row => row.email, sortable: true },
//         { name: 'Area', selector: row => row.addresses[0]?.area || 'N/A', sortable: true },
//         { name: 'Contact', selector: row => row.contact, sortable: true },
//     ];

//     const ExpandedComponent = ({ data }) => (
//         <div className="p-4 bg-gray-100 rounded-md">
//             <p><strong>Address:</strong> {data.addresses[0]?.address || 'N/A'}</p>
//             <p><strong>Hint:</strong> {data.addresses[0]?.hint || 'N/A'}</p>
//             <p><strong>Save As:</strong> {data.addresses[0]?.saveAs || 'N/A'}</p>
//             <p><strong>Latitude:</strong> {data.addresses[0]?.latitude || 'N/A'}</p>
//             <p><strong>Longitude:</strong> {data.addresses[0]?.longitude || 'N/A'}</p>
//             <p><strong>Referred By:</strong> {data.referredby.reference || 'N/A'}</p>
//         </div>
//     );

//     if (loading) {
//         return (
//             <div className="flex justify-center items-center h-screen">
//                 <div className="spinner-grow text-green-500" role="status">
//                     <span className="sr-only">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="flex flex-col items-center p-6">
//             <h1 className="mb-6 text-2xl font-bold">Users</h1>

//             <div className="mb-4 flex w-full max-w-4xl justify-between items-center">
//                 <select
//                     className="form-select p-2 border border-gray-300 rounded-md mr-3"
//                     value={searchField}
//                     onChange={handleSearchFieldChange}
//                 >
//                     <option value="username">Username</option>
//                     <option value="email">Email</option>
//                     <option value="contact">Contact</option>
//                     <option value="gender">Gender</option>
//                     <option value="addresses[0].area">Area</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="p-2 border border-gray-300 rounded-md w-full max-w-md"
//                 />
//             </div>

//             <div className="w-full max-w-4xl">
//                 <DataTable
//                     columns={columns}
//                     data={filteredUsers}
//                     expandableRows
//                     expandableRowsComponent={ExpandedComponent}
//                     pagination
//                     highlightOnHover
//                     responsive
//                     className="shadow-md rounded-lg overflow-hidden"
//                 />
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import DataTable from 'react-data-table-component';
// import config from '../../config';
// import './DataTableStyles.css';
// import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

// export default function Users() {
//     const [users, setUsers] = useState([]);
//     const [filteredUsers, setFilteredUsers] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('username');
//     const [expandedRows, setExpandedRows] = useState({});
//     const { API_URL } = config;

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/fetchAllUsers`)
//             .then(res => res.json())
//             .then(data => {
//                 setUsers(data.users);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching users:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         const filtered = users.filter(user =>
//             user[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredUsers(filtered);
//     }, [searchTerm, searchField, users]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     // Toggle row expansion
//     const toggleRowExpansion = (userId) => {
//         setExpandedRows((prev) => ({
//             ...prev,
//             [userId]: !prev[userId]
//         }));
//     };

//     const columns = [
//         { name: 'Username', selector: row => row.username, sortable: true },
//         { name: 'Email', selector: row => row.email, sortable: true },
//         { name: 'Area', selector: row => row.addresses[0]?.area || 'N/A', sortable: true },
//         { name: 'Contact', selector: row => row.contact, sortable: true },
//         {
//             name: '',
//             cell: (row) => (
//                 <button onClick={() => toggleRowExpansion(row._id)} className="text-gray-500 hover:text-gray-700 focus:outline-none">
//                     {expandedRows[row._id] ? <FaChevronDown /> : <FaChevronRight />}
//                 </button>
//             ),
//             ignoreRowClick: true,
//             allowOverflow: true,
//             button: true,
//         }
//     ];

//     const ExpandedRow = ({ data }) => (
//         <div className="p-4 bg-gray-100 rounded-md">
//             <p><strong>Address:</strong> {data.addresses[0]?.address || 'N/A'}</p>
//             <p><strong>Hint:</strong> {data.addresses[0]?.hint || 'N/A'}</p>
//             <p><strong>Save As:</strong> {data.addresses[0]?.saveAs || 'N/A'}</p>
//             <p><strong>Latitude:</strong> {data.addresses[0]?.latitude || 'N/A'}</p>
//             <p><strong>Longitude:</strong> {data.addresses[0]?.longitude || 'N/A'}</p>
//             <p><strong>Referred By:</strong> {data.referredby.reference || 'N/A'}</p>
//         </div>
//     );

//     if (loading) {
//         return (
//             <div className="flex justify-center items-center h-screen">
//                 <div className="spinner-grow text-green-500" role="status">
//                     <span className="sr-only">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="dataTable-container">
//             <h1 className="mb-6 text-2xl font-bold">Users</h1>

//             <div className="mb-4 flex w-full max-w-4xl justify-between items-center">
//                 <select
//                     className="form-select p-2 border border-gray-300 rounded-md mr-3"
//                     value={searchField}
//                     onChange={handleSearchFieldChange}
//                 >
//                     <option value="username">Username</option>
//                     <option value="email">Email</option>
//                     <option value="contact">Contact</option>
//                     <option value="gender">Gender</option>
//                     <option value="addresses[0].area">Area</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="p-2 border border-gray-300 rounded-md w-full max-w-md"
//                 />
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredUsers}
//                 pagination
//                 highlightOnHover
//                 responsive
//                 className="shadow-md rounded-lg overflow-hidden"
//                 expandableRows
//                 expandableRowsComponent={(row) => expandedRows[row.data._id] && <ExpandedRow data={row.data} />}
//             />
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import config from '../../config';

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const { API_URL } = config;

  useEffect(() => {
    fetch(`${API_URL}/api/auth/fetchAllUsers`)
      .then((res) => res.json())
      .then((data) => {
        setUsers(data.users);
        console.log(data.users);
        setFilteredUsers(data.users);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  }, [API_URL]);

  const toggleRow = (id) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(searchValue) ||
      user.email.toLowerCase().includes(searchValue) ||
      (user.addresses[0]?.area || '').toLowerCase().includes(searchValue) ||
      user.contact.toLowerCase().includes(searchValue)
    );

    setFilteredUsers(filtered);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[98vh]">
        <div className="text-center text-xl font-semibold">Loading...</div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">USERS</h1>

      {/* Search Bar */}
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search by username, email, area, or contact..."
        className="mb-4 p-2 border border-gray-300 w-full rounded-xl"
      />

      <table className="min-w-full bg-white">
        <thead>
          <tr className="bg-gray-100 rounded-t-lg">
            <th className="py-2 px-4  border-black border-b text-left font-semibold rounded-tl-lg">Username</th>
            <th className="py-2 px-4 border-black border-b text-left font-semibold">Email</th>
            <th className="py-2 px-4 border-black border-b text-left font-semibold">Area</th>
            <th className="py-2 px-4 border-black border-b text-left font-semibold">Contact</th>
            <th className="py-2 px-4 border-black border-b text-left font-semibold rounded-tr-lg">More Details</th>
          </tr>
        </thead>

        <tbody>
          {filteredUsers.map((user) => (
            <React.Fragment key={user._id}>
              <tr className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b">{user.username}</td>
                <td className="py-2 px-4 border-b">{user.email}</td>
                <td className="py-2 px-4 border-b">{user.addresses[0]?.area || 'N/A'}</td>
                <td className="py-2 px-4 border-b">{user.contact}</td>
                <td className="py-2 px-4 border-b text-center">
                  <button
                    onClick={() => toggleRow(user._id)}
                    className="text-blue-500 hover:underline focus:outline-none"
                  >
                    {expandedRows[user._id] ? '▲' : '▼'}
                  </button>
                </td>
              </tr>
              {expandedRows[user._id] && (
                <tr className="bg-white">
                  <td colSpan="5" className="p-4 border-b">
                    <div className="flex justify-between">
                      <div className="space-y-2">
                        <h4 className="font-semibold text-[22px]">Additional Details</h4>
                        <div className='flex items-center gap-3'>
                          {user.userImage && (
                            <img
                              src={user.userImage}
                              alt={`${user.username}'s profile`}
                              className="w-16 h-16 rounded-full mt-2"
                            />
                          )}
                          <p className='font-semibold text-[18px] text-[#23272E] leading-[22px]'>{user.username}</p>
                        </div>
                      </div>
                      <div className='flex gap-3'>
                        <div className='w-[1px] h-[90%] bg-[#DBDEDA]'></div>
                        <div>
                          <p className='font-medium text-[13px] leading-[15.28px] text-[#8B909A] mb-2'>USER INFORMATION</p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Area: <span className='font-semibold'>{user.addresses[0].area || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Address: <span className='font-semibold'>{user.addresses[0].address || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Hint: <span className='font-semibold'>{user.addresses[0].hint || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Contact: <span className='font-semibold'>{user.contact || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Gender: <span className='font-semibold'>{user.gender || 'N/A'}</span></p>
                        </div>
                      </div>
                      <div className='flex gap-3'>
                        <div className='w-[1px] h-[90%] bg-[#DBDEDA]'></div>
                        <div>
                          <p className='font-medium text-[13px] leading-[15.28px] text-[#8B909A] mb-2'>OTHER INFORMATION</p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Referred By: <span className='font-semibold'>{user.referredby?.reference || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Referral Code: <span className='font-semibold'>{user.referredby?.referredby || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Latitude: <span className='font-semibold'>{user.addresses[0].latitude || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Longitude: <span className='font-semibold'>{user.addresses[0].longitude || 'N/A'}</span></p>
                          <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>SaveAs: <span className='font-semibold'>{user.addresses[0].saveAs || 'N/A'}</span></p>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
