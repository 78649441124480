// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import config from '../config';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
// Your Firebase configuration
const firebaseConfig = {
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  projectId: config.projectId,
  storageBucket: config.storageBucket,
  messagingSenderId: config.messagingSenderId,
  appId: config.appId,
};

// console.log("firebaseConfig", config);
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth();

// Initialize Firestore
export const db = getFirestore(app);
export default app;
export { storage, auth };