// config.js
export default {
    ADMIN: 'admin@nukkad.com',
    PASSWORD: 'admin',
    API_URL: 'https://nukkad-foods-backend-vercel.vercel.app' ,
    // API_URL:  'http://localhost:5000'         
    apiKey: "AIzaSyAkHuY5O3I7l8eQA_InDBGyBMefeWvHDzQ",
    authDomain: "nukkad-foods.firebaseapp.com",
    projectId: "nukkad-foods",
    storageBucket: "nukkad-foods.appspot.com",
    messagingSenderId: "170641463923",
    appId: "1:170641463923:web:df0b2758c4d929eadd2806",
    measurementId: "G-GHQJL8K6Y0"
  };
          

  // 'https://nukkad-foods-backend-vercel.vercel.app',