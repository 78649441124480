// import React from "react";

// const DefaultComponent = () => {
//   return (
//     <div className="text-center p-5">
//       <h2 className="text-xl font-bold">Invalid or Missing Type</h2>
//       <p>Please check the URL and try again.</p>
//     </div>
//   );
// };

// export default DefaultComponent;

import DetectDevice from "./DetectDevice";

const DefaultComponent = () => {
  return (
    <div className="text-center p-5">
      <h1 className="text-2xl font-bold">Redirecting...</h1>
      <p className="mt-3">
        <DetectDevice/>
      </p>
    </div>
  );
};

export default DefaultComponent;