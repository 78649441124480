import logo from './logo.svg';
import './App.css';
import SignupAdmin from './screens/SignupAdmin';
import SigninAdmin from './screens/SigninAdmin';
import Process from './screens/Process';
import Option from './screens/Option';
import DefaultInvite from './components/Invite/DefaultComponent'

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import SigninSubAdmin from './screens/SigninSubAdmin';
import { db } from './repository/firebase_init';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, setDoc, getDoc, query, where } from 'firebase/firestore';
import { useEffect } from 'react';

function App() {
  // const Download = () => {
  //   useEffect(() => {
  //     const fileUrl = '/assetlinks.json';
  //     const link = document.createElement("a");
  //     link.href = fileUrl;
  //     link.download = "assetlinks.json";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }, []);
  //   return <h1>Your download will start automatically...</h1>;
  // }
  return (
    <div className="" style={{ backgroundColor: "#7fffd4", minHeight: "100vh" }}>
      <Router>
        <Routes>
          <Route path="/signupadmin" element={<SignupAdmin/>} />
          <Route path="/" element={<SigninAdmin/>} />
          <Route path="/signinsubadmin" element={<SigninSubAdmin/>} />
          <Route path="/process" element={<Process/>} />
          <Route path="/invite/:type" element={<Option/>} />
          <Route path="/invite" element={<DefaultInvite />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
