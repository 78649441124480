// import React from 'react'

// const Referral = () => {
//     return (
//         <div className=' flex justify-center items-center h-full '>
//             <div className='w-[70vw] h-[80vh] bg-red-900 rounded-xl'>

//                 <h1 className='text-[36px] text-center font-bold p-1'>NUKKAD REFERRAL LINK</h1>

//                 <div className='flex flex-col justify-evenly h-full bg-black'>

//                     <div className='bg-blue-900 h-[20vh] px-7 py-2 flex flex-col items-center gap-2'>
//                        <h2 className='text-[24px] font-semibold'>Nukkad User Referral link</h2>
//                        <p className='text-[18px] font-medium '><span className='font-semibold'>LINK:-</span> https://example.com</p>
//                        <button>Clip for User</button>
//                     </div>

//                     <div className='bg-blue-900 h-[20vh] px-7 py-2 flex flex-col items-center gap-2'>
//                        <h2 className='text-[24px] font-semibold'>Nukkad Admin Referral link</h2>
//                        <p className='text-[18px] font-medium '><span className='font-semibold'>LINK:-</span> https://example.com</p>
//                        <button>Clip for Admin</button>
//                     </div>

//                     <div className='bg-blue-900 h-[20vh] px-7 py-2 flex flex-col items-center gap-2'>
//                        <h2 className='text-[24px] font-semibold'>Nukkad User driver link</h2>
//                        <p className='text-[18px] font-medium '><span className='font-semibold'>LINK:-</span> https://example.com</p>
//                        <button>Clip for Driver</button>
//                     </div>

//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Referral


import React from 'react';

const Referral = () => {
  // Copy link to clipboard
  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link);
    alert(`Copied: ${link}`);
  };

  let admin_id = localStorage.getItem("admin", "")

  return (
    <div className="flex justify-center min-h-screen">
      <div className="w-[70vw] h-[80vh] bg-white rounded-xl">

        <div className="flex flex-col justify-evenly h-auto bg-gray-50 p-5 gap-4">
        <h1 className="text-[36px] text-center font-bold  text-blue-700">Nukkad Referral Links</h1>
          {[
            { title: 'Nukkad User Referral Link', link: `https://nukkadfoods.com/invite/nukkad-foods?code=${admin_id}` },
            { title: 'Nukkad Restaurant Referral Link', link: `https://nukkadfoods.com/invite/nukkad?code=${admin_id}` },
            { title: 'Nukkad Driver Referral Link', link: `https://nukkadfoods.com/invite/nukkad-delivery-partner?code=${admin_id}` },
          ].map(({ title, link }, index) => (
            <div
              key={index}
              className="bg-blue-100 p-5 flex flex-col items-center gap-3 rounded-lg shadow-md hover:shadow-lg"
            >
              <h2 className="text-[24px] font-semibold text-blue-800">{title}</h2>
              <p className="text-[18px] font-medium text-gray-700">
                <span className="font-semibold">Link: </span>
                {link}
              </p>
              <button
                onClick={() => copyToClipboard(link)}
                className="bg-blue-700 text-white px-4 py-2 rounded-full shadow-md hover:bg-blue-800 transition"
              >
                Copy Link
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Referral;
