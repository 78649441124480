import React, { useState, useEffect } from 'react';
import './Analytics.css'; // We'll create this CSS file for custom styling
import config from '../../config';

export default function Home() {
    const [analyticsData, setAnalyticsData] = useState({
        totalOrders: 0,
        totalUsers: 0,
        totalComplaints: 0,
        totalDeliveryBoys: 0,
        totalRestaurants: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { API_URL } = config;

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    const fetchAnalyticsData = async () => {
        try {
            const response = await fetch(`${API_URL}/api/analytics`);
            const data = await response.json();
            if (data.executed) {
                setAnalyticsData(data);
            } else {
                setError('Failed to fetch analytics data');
            }
        } catch (error) {
            setError('An error occurred while fetching data');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <div className="analytics-container"><h2>Loading...</h2></div>;
    }

    if (error) {
        return <div className="analytics-container"><h2>Error: {error}</h2></div>;
    }

    return (
        <div className="analytics-container">
            <h1 className="text-2xl font-bold mb-4">NukkadFoods Analytics</h1>
            
            <div className="analytics-grid">
                <div className="analytics-card">
                    <h2>Total Users</h2>
                    <p className="analytics-value">{analyticsData.totalUsers}</p>
                </div>
                
                <div className="analytics-card">
                    <h2>Total Restaurants</h2>
                    <p className="analytics-value">{analyticsData.totalRestaurants}</p>
                </div>
                
                <div className="analytics-card">
                    <h2>Total Delivery Boys</h2>
                    <p className="analytics-value">{analyticsData.totalDeliveryBoys}</p>
                </div>
                
                <div className="analytics-card">
                    <h2>Total Orders</h2>
                    <p className="analytics-value">{analyticsData.totalOrders}</p>
                </div>
                
                <div className="analytics-card">
                    <h2>Total Complaints</h2>
                    <p className="analytics-value">{analyticsData.totalComplaints}</p>
                </div>
            </div>
        </div>
    );
}
