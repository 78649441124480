// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css'; // Import your custom CSS file for styling

// export default function DeliveryBoys() {
//     const [deliveryBoys, setDeliveryBoys] = useState([]);
//     const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('name'); // Default search field
//     const { API_URL } = config;

//     const fetchDeliveryBoys = () => {
//         fetch(`${API_URL}/api/auth/getAllDeliveryBoys`)
//             .then(res => res.json())
//             .then(data => {
//                 console.log(data);
//                 setDeliveryBoys(data.deliveryBoys);
//                 setFilteredDeliveryBoys(data.deliveryBoys); // Initialize filtered list with all delivery boys
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching delivery boys:", error);
//                 setLoading(false);
//             });
//     }


//     useEffect(() => {
//         fetchDeliveryBoys();
//     }, []);

//     useEffect(() => {
//         // Filter delivery boys based on search term and field
//         const filtered = deliveryBoys.filter(deliveryBoy =>
//             deliveryBoy[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredDeliveryBoys(filtered);
//     }, [searchTerm, searchField, deliveryBoys]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     const handleDelete = (deliveryBoyId) => {

//         fetch(`${API_URL}/api/auth/deleteDeliveryBoyById/${deliveryBoyId}`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             // body: JSON.stringify({ id: deliveryBoyId }),
//         })
//             .then(res =>

//                 res.json())
//             .then(data => {
//                 alert(data.message);
//                 if (data.executed) {
//                     fetchDeliveryBoys();
//                 }
//             })
//             .catch(error => {
//                 console.error("Error deleting delivery boy:", error);
//                 alert("Failed to delete delivery boy");
//             });
//     }

//     if (loading) {
//         return (
//             <div className='d-flex flex-column justify-center' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", padding: "20px" }}>
//             <h1 className='mt-3 mb-4 fw-bold'>Delivery Boys</h1>

//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={handleSearchFieldChange} 
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="name">Name</option>
//                     <option value="email">Email</option>
//                     <option value="contact">Contact</option>
//                     <option value="city">City</option>
//                     <option value="gender">Gender</option>
//                     <option value="DOB">Date of Birth</option>
//                     <option value="whatsappContact">WhatsApp Contact</option>
//                     <option value="alternateNumber">Alternate Number</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>

//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">ID</th>
//                                 <th scope="col">Name</th>
//                                 <th scope="col">Email</th>
//                                 <th scope="col">Contact</th>
//                                 <th scope="col">City</th>
//                                 <th scope="col">Gender</th>
//                                 <th scope="col">DOB</th>
//                                 <th scope="col">Reference</th>
//                                 <th scope="col">Executive ID</th>
//                                 <th scope="col">Executive Name</th>
//                                 <th scope="col">WhatsApp Contact</th>
//                                 <th scope="col">Alternate Number</th>
//                                 <th scope="col">Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredDeliveryBoys.map(deliveryBoy => (
//                                 <tr key={deliveryBoy._id}>
//                                     <td>{deliveryBoy._id}</td>
//                                     <td>{deliveryBoy.name}</td>
//                                     <td>{deliveryBoy.email}</td>
//                                     <td>{deliveryBoy.contact}</td>
//                                     <td>{deliveryBoy.city}</td>
//                                     <td>{deliveryBoy.gender}</td>
//                                     <td>{new Date(deliveryBoy.DOB).toLocaleDateString()}</td>
//                                     <td>{deliveryBoy.isReferred?.reference || 'N/A'}</td>
//                                     <td>{deliveryBoy.isReferred?.executiveId || 'N/A'}</td>
//                                     <td>{deliveryBoy.isReferred?.executiveName || 'N/A'}</td>
//                                     <td>{deliveryBoy.whatsappContact}</td>
//                                     <td>{deliveryBoy.alternateNumber}</td>
//                                     <td>
//                                         <button
//                                             className="btn btn-danger btn-sm"
//                                             onClick={() => handleDelete(deliveryBoy._id)}
//                                         >
//                                             Delete
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// // import './DeliveryBoys.css';

// export default function DeliveryBoys() {
//     const [deliveryBoys, setDeliveryBoys] = useState([]);
//     const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
//     const [expandedRows, setExpandedRows] = useState({});
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('name');
//     const { API_URL } = config;

//     useEffect(() => {
//         fetchDeliveryBoys();
//     }, []);

//     const fetchDeliveryBoys = async () => {
//         try {
//             const response = await fetch(`${API_URL}/api/auth/getAllDeliveryBoys`);
//             const data = await response.json();
//             setDeliveryBoys(data.deliveryBoys || []);
//             setFilteredDeliveryBoys(data.deliveryBoys || []);
//             setLoading(false);
//         } catch (error) {
//             console.error("Error fetching delivery boys:", error);
//             setLoading(false);
//         }
//     };

//     const toggleRow = (id) => {
//         setExpandedRows((prevState) => ({
//             ...prevState,
//             [id]: !prevState[id],
//         }));
//     };

//     const handleSearchTermChange = (event) => {
//         setSearchTerm(event.target.value.toLowerCase());
//         filterDeliveryBoys(event.target.value.toLowerCase(), searchField);
//     };

//     const handleSearchFieldChange = (event) => {
//         setSearchField(event.target.value);
//         filterDeliveryBoys(searchTerm, event.target.value);
//     };

//     const filterDeliveryBoys = (searchValue, field) => {
//         const filtered = deliveryBoys.filter((boy) =>
//             boy[field]?.toString().toLowerCase().includes(searchValue)
//         );
//         setFilteredDeliveryBoys(filtered);
//     };

//     const handleDelete = async (deliveryBoyId) => {
//         if (window.confirm("Are you sure you want to delete this delivery boy?")) {
//             try {
//                 const response = await fetch(`${API_URL}/api/auth/deleteDeliveryBoyById/${deliveryBoyId}`, {
//                     method: 'POST',
//                     headers: { 'Content-Type': 'application/json' },
//                 });
//                 const data = await response.json();
//                 alert(data.message);
//                 if (data.executed) fetchDeliveryBoys();
//             } catch (error) {
//                 console.error("Error deleting delivery boy:", error);
//             }
//         }
//     };

//     if (loading) {
//         return (
//             <div className="loading-container">
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="delivery-boys-container">
//             <h1 className="page-title">Delivery Boys</h1>

//             <div className="search-container">
//                 <select
//                     className="form-select search-field"
//                     value={searchField}
//                     onChange={handleSearchFieldChange}
//                 >
//                     <option value="name">Name</option>
//                     <option value="email">Email</option>
//                     <option value="contact">Contact</option>
//                     <option value="city">City</option>
//                     <option value="gender">Gender</option>
//                     <option value="DOB">Date of Birth</option>
//                     <option value="whatsappContact">WhatsApp Contact</option>
//                     <option value="alternateNumber">Alternate Number</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control search-input"
//                 />
//             </div>

//             <div className="table-container">
//                 <table className="table table-hover">
//                     <thead>
//                         <tr>
//                             <th>ID</th>
//                             <th>Name</th>
//                             <th>Email</th>
//                             <th>Contact</th>
//                             <th>City</th>
//                             <th>Action</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {filteredDeliveryBoys.map((boy) => (
//                             <React.Fragment key={boy._id}>
//                                 <tr>
//                                     <td>{boy._id}</td>
//                                     <td>{boy.firstName} {boy.lastName}</td>
//                                     <td>{boy.email}</td>
//                                     <td>{boy.contact}</td>
//                                     <td>{boy.city}</td>
//                                     <td>
//                                         <button
//                                             className="btn btn-link"
//                                             onClick={() => toggleRow(boy._id)}
//                                         >
//                                             {expandedRows[boy._id] ? 'Hide Details' : 'Show Details'}
//                                         </button>
//                                         <button
//                                             className="btn btn-danger btn-sm ms-2"
//                                             onClick={() => handleDelete(boy._id)}
//                                         >
//                                             Delete
//                                         </button>
//                                     </td>
//                                 </tr>
//                                 {expandedRows[boy._id] && (
//                                     <tr>
//                                         <td colSpan="6">
//                                             <div className="expanded-row">
//                                                 <p><strong>Gender:</strong> {boy.gender}</p>
//                                                 <p><strong>Date of Birth:</strong> {new Date(boy.DOB).toLocaleDateString()}</p>
//                                                 <p><strong>WhatsApp Contact:</strong> {boy.whatsappContact || 'N/A'}</p>
//                                                 <p><strong>Alternate Number:</strong> {boy.alternateNumber || 'N/A'}</p>
//                                                 <p><strong>Bank Details:</strong> 
//                                                     {`Account: ${boy.bankDetails?.accountNumber || 'N/A'}, 
//                                                     IFSC: ${boy.bankDetails?.IFSCCode || 'N/A'}`}
//                                                 </p>
//                                                 <div>
//                                                     <strong>ID Proof:</strong> <br />
//                                                     <img src={boy.idProofPic} alt="ID Proof" className="verification-img" />
//                                                 </div>
//                                                 <div>
//                                                     <strong>Driving License:</strong> <br />
//                                                     <img src={boy.drivingLicensePic} alt="Driving License" className="verification-img" />
//                                                 </div>
//                                             </div>
//                                         </td>
//                                     </tr>
//                                 )}
//                             </React.Fragment>
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import config from '../../config';
// import './DeliveryBoys.css'; // Optional custom styles
import { Modal, Button } from 'react-bootstrap'; // Using React-Bootstrap for modal

export default function DeliveryBoys() {
    const [deliveryBoys, setDeliveryBoys] = useState([]);
    const [filteredDeliveryBoys, setFilteredDeliveryBoys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('name'); // Default search field
    const [showModal, setShowModal] = useState(false); // Modal visibility state
    const [selectedDeliveryBoy, setSelectedDeliveryBoy] = useState(null); // Selected delivery boy details
    const { API_URL } = config;

    const fetchDeliveryBoys = async () => {
        try {
            const res = await fetch(`${API_URL}/api/auth/getAllDeliveryBoys`);
            const data = await res.json();
            setDeliveryBoys(data.deliveryBoys || []);
            setFilteredDeliveryBoys(data.deliveryBoys || []); // Initialize filtered list
            setLoading(false);
        } catch (error) {
            console.error("Error fetching delivery boys:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDeliveryBoys();
    }, []);

    useEffect(() => {
        // Filter delivery boys based on search term and field
        const filtered = deliveryBoys.filter(deliveryBoy =>
            deliveryBoy[searchField]?.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDeliveryBoys(filtered);
    }, [searchTerm, searchField, deliveryBoys]);

    const handleSearchTermChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleSearchFieldChange = event => {
        setSearchField(event.target.value);
    };

    const handleDelete = async (deliveryBoyId) => {
        if (window.confirm("Are you sure you want to delete this delivery boy?")) {
            try {
                const res = await fetch(`${API_URL}/api/auth/deleteDeliveryBoyById/${deliveryBoyId}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await res.json();
                alert(data.message);
                if (data.executed) fetchDeliveryBoys();
            } catch (error) {
                console.error("Error deleting delivery boy:", error);
                alert("Failed to delete delivery boy");
            }
        }
    };

    const handleShowModal = (deliveryBoy) => {
        setSelectedDeliveryBoy(deliveryBoy);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedDeliveryBoy(null);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "98vh" }}>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="container py-4">
            <h1 className="text-2xl font-bold mb-4">DELIVERY BOY</h1>

            <div className="d-flex mb-3">
                <select
                    className="form-select me-3"
                    value={searchField}
                    onChange={handleSearchFieldChange}
                    style={{ maxWidth: '200px' }}
                >
                    <option value="name">Name</option>
                    <option value="email">Email</option>
                    <option value="contact">Contact</option>
                    <option value="city">City</option>
                    {/* <option value="gender">Gender</option> */}
                </select>

                <input
                    type="text"
                    placeholder={`Search by ${searchField}...`}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    className="form-control rounded-xl"
                />
            </div>

            <div className="table-responsive">
                <table className="table table-hover bg-white">
                    <thead>
                        <tr className="bg-gray-100 rounded-t-lg">
                            <th className="py-2 px-4 border-black border-b text-left font-semibold rounded-tl-lg">Name</th>
                            <th className="py-2 px-4 border-black border-b text-left font-semibold">Email</th>
                            <th className="py-2 px-4 border-black border-b text-left font-semibold">Contact</th>
                            <th className="py-2 px-4 border-black border-b text-left font-semibold">City</th>
                            <th className="py-2 px-4 border-black border-b text-left font-semibold rounded-tr-lg">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredDeliveryBoys.map(deliveryBoy => (
                            <tr key={deliveryBoy._id}>
                                <td className="py-2 px-4 border-b">{deliveryBoy.name}</td>
                                <td className="py-2 px-4 border-b">{deliveryBoy.email}</td>
                                <td className="py-2 px-4 border-b">{deliveryBoy.contact}</td>
                                <td className="py-2 px-4 border-b">{deliveryBoy.city}</td>
                                <td>
                                    <button
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={() => handleShowModal(deliveryBoy)}
                                    >
                                        Details
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm"
                                        onClick={() => handleDelete(deliveryBoy._id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {selectedDeliveryBoy && (
                <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Delivery Boy Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            {/* Personal Details */}
                            <h5 className="mb-3">Personal Details</h5>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <p><strong>Name:</strong> {selectedDeliveryBoy.name}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Gender:</strong> {selectedDeliveryBoy.gender || 'N/A'}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Date of Birth:</strong> {new Date(selectedDeliveryBoy.DOB).toLocaleDateString()}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>City:</strong> {selectedDeliveryBoy.city || 'N/A'}</p>
                                </div>
                            </div>

                            {/* Contact Information */}
                            <h5 className="mb-3">Contact Information</h5>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <p><strong>Email:</strong> {selectedDeliveryBoy.email}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Contact:</strong> {selectedDeliveryBoy.contact}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>WhatsApp Contact:</strong> {selectedDeliveryBoy.whatsappContact || 'N/A'}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Alternate Number:</strong> {selectedDeliveryBoy.alternateNumber || 'N/A'}</p>
                                </div>
                            </div>

                            {/* Professional Details */}
                            <h5 className="mb-3">Professional Details</h5>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <p><strong>Employee ID:</strong> {selectedDeliveryBoy.employeeId || 'N/A'}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Joining Date:</strong> {new Date(selectedDeliveryBoy.joiningDate).toLocaleDateString()}</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Experience:</strong> {selectedDeliveryBoy.experience || 'N/A'} years</p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Skills:</strong> {selectedDeliveryBoy.skills?.join(', ') || 'N/A'}</p>
                                </div>
                            </div>

                            {/* Document Images */}
                            <h5 className="mb-3">Uploaded Documents</h5>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <p>
                                        <strong>PAN Card:</strong>
                                        {selectedDeliveryBoy.pancardPic ? (
                                            <Button className='ml-2'
                                                variant="link"
                                                onClick={() => window.open(selectedDeliveryBoy.pancardPic, '_blank')}
                                            >
                                                View Image
                                            </Button>
                                        ) : (
                                            ' Not Available'
                                        )}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p>
                                        <strong>ID Proof:</strong>
                                        {selectedDeliveryBoy.idProofPic ? (
                                            <Button className='ml-2'
                                                variant="link"
                                                onClick={() => window.open(selectedDeliveryBoy.idProofPic, '_blank')}
                                            >
                                                View Image
                                            </Button>
                                        ) : (
                                            ' Not Available'
                                        )}
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p>
                                        <strong>Driving License:</strong>
                                        {selectedDeliveryBoy.drivingLicensePic ? (
                                            <Button className='ml-2'
                                                variant="link"
                                                onClick={() => window.open(selectedDeliveryBoy.drivingLicensePic, '_blank')}
                                            >
                                                View Image
                                            </Button>
                                        ) : (
                                            ' Not Available'
                                        )}
                                    </p>
                                </div>
                            </div>

                            {/* Bank Details */}
                            <h5 className="mb-3">Bank Details</h5>
                            {selectedDeliveryBoy.bankDetails ? (
                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Account Number</th>
                                                <th>IFSC Code</th>
                                                <th>Branch</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{selectedDeliveryBoy.bankDetails.accountNumber || 'N/A'}</td>
                                                <td>{selectedDeliveryBoy.bankDetails.IFSCCode || 'N/A'}</td>
                                                <td>{selectedDeliveryBoy.bankDetails.branchCode || 'N/A'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p>No bank details available.</p>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

        </div>
    );
}
