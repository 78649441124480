// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css'; // Import your custom CSS file for styling

// export default function Restaurants() {
//     const [restaurants, setRestaurants] = useState([]);
//     const [filteredRestaurants, setFilteredRestaurants] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('nukkadName'); // Default search field
//     const [statusFilter, setStatusFilter] = useState('all'); // Default status filter
//     const { API_URL } = config;


//     const fetchRestaurants = async () => {

//         fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//             .then(res => res.json())
//             .then(data => {
//                 console.log(data);
//                 setRestaurants(data.restaurants || []);
//                 setFilteredRestaurants(data.restaurants  || []); // Initialize filtered list with all restaurants
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching restaurants:", error);
//                 setLoading(false);
//             });

//     }
//     useEffect(() => {
//         fetchRestaurants()
//     }, [API_URL]);

//     useEffect(() => {
//         // Filter restaurants based on search term, search field, and status filter
//         const filtered = restaurants.filter(restaurant =>
//             restaurant[searchField]?.toLowerCase().includes(searchTerm.toLowerCase()) &&
//             (statusFilter === 'all' || restaurant.status === statusFilter)
//         );
//         setFilteredRestaurants(filtered);
//     }, [searchTerm, searchField, statusFilter, restaurants]);

//     const handleSearchTermChange = event => {
//         setSearchTerm(event.target.value);
//     };

//     const handleSearchFieldChange = event => {
//         setSearchField(event.target.value);
//     };

//     const handleStatusFilterChange = status => {
//         setStatusFilter(status);
//     };

//     const handleDelete = (id) => {
//         alert("Are you sure you want to delete this restaurant?")
//         fetch(`${API_URL}/api/auth/DeleteRestaurantById`, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ id: id }),
//         })
//             .then(res => res.json())
//             .then(data => {
//                 console.log(data);
//                 alert(data.message)
//                 if(data.executed)
//                 {
//                     fetchRestaurants()
//                 }
//             })
//             .catch(error => {
//                 console.error("Error deleting restaurant:", error);
//                 // Handle error
//             });
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column ' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", maxWidth: "100vw", padding: "20px" }}>
//             <h1 className='mt-3 mb-4 fw-bold'>Restaurants</h1>

//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%"}}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={handleSearchFieldChange} 
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="nukkadName">Name</option>
//                     <option value="city">City</option>
//                     <option value="pincode">Pincode</option>
//                     <option value="landmark">Landmark</option>
//                     <option value="ownerName">Owner Name</option>
//                     <option value="phoneNumber">Phone Number</option>
//                     <option value="status">Status</option>
//                     <option value="nukkadAddress">Nukkad Address</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={handleSearchTermChange}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>

//             <div className="mb-3">
//                 <button 
//                     className={`btn btn-${statusFilter === 'all' ? 'primary' : 'secondary'} me-2`} 
//                     onClick={() => handleStatusFilterChange('all')}>
//                     All
//                 </button>
//                 <button 
//                     className={`btn btn-${statusFilter === 'verified' ? 'primary' : 'secondary'} me-2`} 
//                     onClick={() => handleStatusFilterChange('verified')}>
//                     Verified
//                 </button>
//                 <button 
//                     className={`btn btn-${statusFilter === 'unverified' ? 'primary' : 'secondary'}`} 
//                     onClick={() => handleStatusFilterChange('unverified')}>
//                     Unverified
//                 </button>
//             </div>

//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%", height: "calc(100vh - 250px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">ID</th>
//                                 <th scope="col">Name</th>
//                                 <th scope="col">Address</th>
//                                 <th scope="col">City</th>
//                                 <th scope="col">Pincode</th>
//                                 <th scope="col">Landmark</th>
//                                 <th scope="col">Phone</th>
//                                 <th scope="col">Owner</th>
//                                 <th scope="col">Reference</th>
//                                 <th scope="col">Executive ID</th>
//                                 <th scope="col">Executive Name</th>
//                                 <th scope="col">Status</th>
//                                 <th scope="col">Action</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredRestaurants.map(restaurant => (
//                                 <tr key={restaurant._id}>
//                                     <td>{restaurant._id}</td>
//                                     <td>{restaurant.nukkadName}</td>
//                                     <td>{restaurant.nukkadAddress}</td>
//                                     <td>{restaurant.city}</td>
//                                     <td>{restaurant.pincode}</td>
//                                     <td>{restaurant.landmark}</td>
//                                     <td>{restaurant.phoneNumber}</td>
//                                     <td>{restaurant.ownerName}</td>
//                                     <td>{restaurant.isReferred?.reference || 'N/A'}</td>
//                                     <td>{restaurant.isReferred?.executiveId || 'N/A'}</td>
//                                     <td>{restaurant.isReferred?.executiveName || 'N/A'}</td>
//                                     <td>{restaurant.status}</td>
//                                     <td><button className="btn btn-danger btn-sm" onClick={() => handleDelete(restaurant._id)}>Delete</button></td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css'; // Import your custom CSS file for styling

// export default function Restaurants() {
//     const [restaurants, setRestaurants] = useState([]);
//     const [filteredRestaurants, setFilteredRestaurants] = useState([]);
//     const [expandedRows, setExpandedRows] = useState({});
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const { API_URL } = config;

//     useEffect(() => {
//         fetchRestaurants();
//     }, [API_URL]);

//     const fetchRestaurants = async () => {
//         try {
//             const res = await fetch(`${API_URL}/api/auth/fetchAllRestaurants`);
//             const data = await res.json();
//             setRestaurants(data.restaurants || []);
//             setFilteredRestaurants(data.restaurants || []);
//             console.log(data.restaurants)
//             setLoading(false);
//         } catch (error) {
//             console.error("Error fetching restaurants:", error);
//             setLoading(false);
//         }
//     };

//     const toggleRow = (id) => {
//         setExpandedRows((prevState) => ({
//             ...prevState,
//             [id]: !prevState[id],
//         }));
//     };

//     const handleSearchTermChange = (event) => {
//         const searchValue = event.target.value.toLowerCase();
//         setSearchTerm(searchValue);

//         const filtered = restaurants.filter((restaurant) =>
//             restaurant.nukkadName.toLowerCase().includes(searchValue) ||
//             restaurant.city.toLowerCase().includes(searchValue) ||
//             restaurant.ownerName.toLowerCase().includes(searchValue) ||
//             restaurant.phoneNumber.includes(searchValue)
//         );

//         setFilteredRestaurants(filtered);
//     };

//     const handleDelete = async (id) => {
//         if (window.confirm("Are you sure you want to delete this restaurant?")) {
//             try {
//                 const res = await fetch(`${API_URL}/api/auth/DeleteRestaurantById`, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ id }),
//                 });
//                 const data = await res.json();
//                 alert(data.message);
//                 if (data.executed) fetchRestaurants();
//             } catch (error) {
//                 console.error("Error deleting restaurant:", error);
//             }
//         }
//     };

//     if (loading) {
//         return (
//             <div className="loading-container">
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="restaurants-container p-8">
//            <h1 className="text-2xl font-bold mb-4">RESTAURANTS</h1>

//             <input
//                 type="text"
//                 value={searchTerm}
//                 onChange={handleSearchTermChange}
//                 placeholder="Search by name, city, owner name, or phone..."
//                 className="form-control mb-3"
//             />

//             <table className="table table-hover">
//                 <thead className="table-light">
//                     <tr>
//                         <th scope="col">Owner Email</th>
//                         <th scope="col">Owner Name</th>
//                         <th scope="col">Nukkad Address</th>
//                         <th scope="col">Owner Contact</th>
//                         <th scope="col">More Details</th>
//                         <th scope="col">Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {filteredRestaurants.map((restaurant) => (
//                         <React.Fragment key={restaurant._id}>
//                             <tr>
//                                 <td>{restaurant.ownerEmail}</td>
//                                 <td>{restaurant.ownerName}</td>
//                                 <td>{restaurant.nukkadAddress}</td>
//                                 <td>{restaurant.ownerContactNumber}</td>
//                                 <td>
//                                     <button
//                                         onClick={() => toggleRow(restaurant._id)}
//                                         className="btn btn-link"
//                                     >
//                                         {expandedRows[restaurant._id] ? '▲' : '▼'}
//                                     </button>
//                                 </td>
//                                 <td>
//                                     <button
//                                         className="btn btn-danger btn-sm"
//                                         onClick={() => handleDelete(restaurant._id)}
//                                     >
//                                         Delete
//                                     </button>
//                                 </td>
//                             </tr>
//                             {expandedRows[restaurant._id] && (
//                                 <tr>
//                                     <td colSpan="6" className="expanded-row">
//                                         <div>
//                                             <h5>Additional Details</h5>
//                                             <p>City: {restaurant.city}</p>
//                                             <p>Landmark: {restaurant.landmark}</p>
//                                             <p>Pincode: {restaurant.pincode}</p>
//                                             <p>Bank Details: {restaurant.bankDetails?.accountNo || 'N/A'}</p>
//                                             <p>Ratings: {restaurant.ratings.join(', ')}</p>
//                                             Add more fields as needed
//                                         </div>
//                                     </td>
//                                 </tr>
//                             )}
//                         </React.Fragment>
//                     ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// }


import React, { useEffect, useState } from 'react';
import config from '../../config';
import './Restaurants.css';
import { FiTrash } from 'react-icons/fi';

export default function Restaurants() {
    const [restaurants, setRestaurants] = useState([]);
    const [filteredRestaurants, setFilteredRestaurants] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('all');
    const { API_URL } = config;

    useEffect(() => {
        fetchRestaurants();
    }, [API_URL]);

    const fetchRestaurants = async () => {
        try {
            const res = await fetch(`${API_URL}/api/auth/fetchAllRestaurants`);
            const data = await res.json();
            setRestaurants(data.restaurants || []);
            console.log(data.restaurants)
            setFilteredRestaurants(data.restaurants || []);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching restaurants:", error);
            setLoading(false);
        }
    };

    const toggleRow = (id) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const handleSearchTermChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setSearchTerm(searchValue);
        filterRestaurants(searchValue, statusFilter);
    };

    const handleStatusFilterChange = (status) => {
        setStatusFilter(status);
        filterRestaurants(searchTerm, status);
    };

    const filterRestaurants = (searchValue, status) => {
        const filtered = restaurants.filter((restaurant) =>
            (restaurant.nukkadName.toLowerCase().includes(searchValue) ||
                restaurant.city.toLowerCase().includes(searchValue) ||
                restaurant.ownerName.toLowerCase().includes(searchValue) ||
                restaurant.phoneNumber.includes(searchValue)) &&
            (status === 'all' || restaurant.status === status)
        );
        setFilteredRestaurants(filtered);
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this restaurant?")) {
            try {
                const res = await fetch(`${API_URL}/api/auth/DeleteRestaurantById`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id }),
                });
                const data = await res.json();
                alert(data.message);
                if (data.executed) fetchRestaurants();
            } catch (error) {
                console.error("Error deleting restaurant:", error);
            }
        }
    };

    if (loading) {
        return (
            <div className='d-flex flex-column ' style={{ height: "98vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="restaurants-container p-8">
            <h1 className="text-2xl font-bold mb-4">RESTAURANTS</h1>

            <input
                type="text"
                value={searchTerm}
                onChange={handleSearchTermChange}
                placeholder="Search by name, city, owner name, or phone..."
                className="form-control mb-3 rounded-xl"
            />

            <div className="mb-3">
                <button
                    className={`btn btn-${statusFilter === 'all' ? 'primary' : 'secondary'} me-2`}
                    onClick={() => handleStatusFilterChange('all')}
                >
                    All
                </button>
                <button
                    className={`btn btn-${statusFilter === 'verified' ? 'primary' : 'secondary'} me-2`}
                    onClick={() => handleStatusFilterChange('verified')}
                >
                    Verified
                </button>
                <button
                    className={`btn btn-${statusFilter === 'unverified' ? 'primary' : 'secondary'}`}
                    onClick={() => handleStatusFilterChange('unverified')}
                >
                    Unverified
                </button>
            </div>

            <table className="table table-hover bg-white rounded-tr-lg">
                {/* <thead className="table-light">
                    <tr className='rounded-t-lg'>
                        <th scope="col" className='rounded-tl-lg'>Owner Email</th>
                        <th scope="col">Owner Name</th>
                        <th scope="col">Nukkad Address</th>
                        <th scope="col">Owner Contact</th>
                        <th scope="col">Status</th>
                        <th scope="col">More</th>
                        <th scope="col" className='rounded-tr-lg'>Action</th>
                    </tr>
                </thead> */}
                <thead className="table-light">
                    <tr>
                        <th scope="col" className="rounded-tl-lg">Owner Email</th>
                        <th scope="col">Owner Name</th>
                        <th scope="col">Nukkad Address</th>
                        <th scope="col">Owner Contact</th>
                        <th scope="col">Status</th>
                        <th scope="col">More</th>
                        <th scope="col" className="rounded-tr-lg bg-red-900">Action</th>
                    </tr>
                </thead>

                <tbody>
                    {filteredRestaurants.map((restaurant) => (
                        <React.Fragment key={restaurant._id}>
                            <tr>
                                <td>{restaurant.ownerEmail}</td>
                                <td>{restaurant.ownerName}</td>
                                <td>{restaurant.nukkadAddress}</td>
                                <td>{restaurant.ownerContactNumber}</td>
                                <td>{restaurant.status}</td>
                                <td>
                                    <button
                                        onClick={() => toggleRow(restaurant._id)}
                                        className="btn btn-link"
                                    >
                                        {expandedRows[restaurant._id] ? '▲' : '▼'}
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className="btn btn-sm text-danger"
                                        onClick={() => handleDelete(restaurant._id)}
                                        title="Delete"
                                    >
                                        <FiTrash size={18} />
                                    </button>
                                </td>
                            </tr>
                            {expandedRows[restaurant._id] && (
                                <tr>
                                    <td colSpan="7" className="expanded-row">
                                        <div className="flex justify-between">
                                            {/* <h5>Additional Details</h5>
                                            <p>City: {restaurant.city}</p>
                                            <p>Landmark: {restaurant.landmark}</p>
                                            <p>Pincode: {restaurant.pincode}</p>
                                            <p>Bank Details: {restaurant.bankDetails?.accountNo || 'N/A'}</p>
                                            <p>Ratings: {restaurant.ratings.join(', ')}</p> */}
                                            {/* Add more fields as needed */}
                                            <div className="">
                                                <h4 className="font-semibold text-[22px] mb-2">Additional Details</h4>
                                                <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>NukkadName: <span className='font-semibold'>{restaurant.nukkadName || 'N/A'}</span></p>
                                                <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>City: <span className='font-semibold'>{restaurant.city || 'N/A'}</span></p>
                                                <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>CurrentAdd: <span className='font-semibold'>{restaurant.currentAddress || 'N/A'}</span></p>
                                                <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Pincode: <span className='font-semibold'>{restaurant.pincode || 'N/A'}</span></p>
                                                <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Landmark: <span className='font-semibold'>{restaurant.landmark || 'N/A'}</span></p>
                                            </div>
                                            <div className='flex gap-3 p-2'>
                                                <div className='w-[1px] h-[90%] bg-[#DBDEDA]'></div>
                                                <div>
                                                    <p className='font-medium text-[13px] leading-[15.28px] text-[#8B909A] mb-2'>OWNER INFORMATION</p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Owner Name: <span className='font-semibold'>{restaurant.ownerName || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Owner Email: <span className='font-semibold'>{restaurant.ownerEmail || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>PermanentAdd: <span className='font-semibold'>{restaurant.permananetAddress || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Phone Number: <span className='font-semibold'>{restaurant.phoneNumber || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>OperationHours: <span className='font-semibold'>{restaurant.operationalHours?.Monday || 'N/A'}</span></p>
                                                </div>
                                            </div>
                                            <div className='flex gap-3 p-2'>
                                                <div className='w-[1px] h-[90%] bg-[#DBDEDA]'></div>
                                                <div>
                                                    <p className='font-medium text-[13px] leading-[15.28px] text-[#8B909A] mb-2'>BANK INFORMATION</p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Account Number: <span className='font-semibold'>{restaurant.bankDetails?.accountNo || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>IFSCcode: <span className='font-semibold'>{restaurant.bankDetails?.IFSCcode || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Bank Branch: <span className='font-semibold'>{restaurant.bankDetails?.bankBranch || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Latitude: <span className='font-semibold'>{restaurant.latitude || 'N/A'}</span></p>
                                                    <p className='font-normal text-[13px] text-[#23272E] leading-[22px]'>Longitude: <span className='font-semibold'>{restaurant.longitude || 'N/A'}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
