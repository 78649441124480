// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css';

// export default function NukkadApproval() {
//     const [nukkadList, setNukkadList] = useState([]);
//     const [filteredNukkadList, setFilteredNukkadList] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('nukkadName');
//     const { API_URL } = config;
//     const [data, setData] = useState({});
//     const [pic, setPic] = useState("");

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//             .then(res => res.json())
//             .then(data => {
//                 const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                 setNukkadList(unverifiedNukkads);
//                 setFilteredNukkadList(unverifiedNukkads);
//                 console.log(unverifiedNukkads)
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching unverified nukkads:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         if (data) {
//             const images = [
//                 data.ownerPhoto,
//                 data.signature,
//                 data.fssaiDetails?.certificate,
//                 data.gstDetails?.gstCertificate,
//                 data.kycDetails?.aadhar,
//                 data.kycDetails?.pan,
//             ];
//             images.forEach((src) => {
//                 if (src) {
//                     const img = new Image();
//                     img.src = src;
//                 }
//             });

//             setPic(data.ownerPhoto);
//         }
//     }, [data]);

//     useEffect(() => {
//         // Filter nukkad list based on search term and field
//         const filtered = nukkadList.filter(nukkad =>
//             nukkad[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredNukkadList(filtered);
//     }, [searchTerm, searchField, nukkadList]);

//     const handleVerifyNukkad = (id) => {

//         try {

//             fetch(`${API_URL}/api/auth/updateRestaurantById`,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ _id: id, updateData: { status: 'verified' } }),
//                 }
//             ).then(res => res.json()).then(data => {
//                 if (data.executed) {
//                     alert('Nukkad verified successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                             setNukkadList(unverifiedNukkads);
//                             setFilteredNukkadList(unverifiedNukkads);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified nukkads:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify nukkad');
//                 }
//             }

//             )
//         } catch (error) {
//             alert('Failed to verify nukkad', error);
//         }
//     };


//     const handleInvalidNukkad = (id) => {

//         try {

//             fetch(`${API_URL}/api/auth/updateRestaurantById`,
//                 {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify({ _id: id, updateData: { status: 'Invalid' } }),
//                 }
//             ).then(res => res.json()).then(data => {
//                 if (data.executed) {
//                     alert('Nukkad Removed successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                             setNukkadList(unverifiedNukkads);
//                             setFilteredNukkadList(unverifiedNukkads);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified nukkads:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify nukkad');
//                 }
//             }

//             )
//         } catch (error) {
//             alert('Failed to verify nukkad', error);
//         }
//     };

//     if (loading) {
//         return (
//             <div className='d-flex flex-column ' style={{ height: "100vh", maxWidth: "100vw", justifyContent: "center", alignItems: "center" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: "100vh", maxWidth: "100vw", padding: "20px"}}>
//             <h1 className='mt-3 mb-4 fw-bold'>Nukkad Approval</h1>

//             <div className="mb-3 d-flex justify-content-between align-items-center" style={{ width: "90%" }}>
//                 <select 
//                     className="form-select me-3" 
//                     value={searchField} 
//                     onChange={(e) => setSearchField(e.target.value)}
//                     style={{ width: "auto", minWidth: "150px" }}
//                 >
//                     <option value="nukkadName">Nukkad Name</option>
//                     <option value="nukkadAddress">Nukkad Address</option>
//                     <option value="city">City</option>
//                     <option value="pincode">Pincode</option>
//                     <option value="ownerName">Owner Name</option>
//                     <option value="ownerEmail">Owner Email</option>
//                     <option value="currentAddress">Current Address</option>
//                     <option value="permananetAddress">Permanent Address</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="form-control"
//                     style={{ width: "60%" }}
//                 />
//             </div>

//             <div className="card shadow-sm d-flex flex-column" style={{ width: "90%",  height: "calc(100vh - 200px)", borderRadius: "15px", overflow: "hidden" }}>
//                 <div className="table-responsive" style={{ flexGrow: 1, overflowY: "auto" }}>
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th scope="col">ID</th>
//                                 <th scope="col">Nukkad Name</th>
//                                 <th scope="col">Nukkad Address</th>
//                                 <th scope='col'>Owner Contact Number</th>
//                                 <th scope="col">City</th>
//                                 <th scope="col">Pincode</th>
//                                 <th scope="col">Owner Name</th>
//                                 <th scope="col">Owner Email</th>
//                                 <th scope="col">Current Address</th>
//                                 <th scope="col">Permanent Address</th>
//                                 <th scope="col">Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredNukkadList.map(nukkad => (
//                                 <tr key={nukkad._id}>
//                                     <td>{nukkad._id}</td>
//                                     <td>{nukkad.nukkadName}</td>
//                                     <td>{nukkad.nukkadAddress}</td>
//                                     <td>{nukkad.ownerContactNumber}</td>
//                                     <td>{nukkad.city}</td>
//                                     <td>{nukkad.pincode}</td>
//                                     <td>{nukkad.ownerName}</td>
//                                     <td>{nukkad.ownerEmail}</td>
//                                     <td>{nukkad.currentAddress}</td>
//                                     <td>{nukkad.permananetAddress}</td>
//                                     <td>
//                                         <button 
//                                             type="button" 
//                                             data-bs-toggle="modal" 
//                                             data-bs-target="#exampleModal"
//                                             onClick={() => {
//                                                 setPic("");
//                                                 setData(nukkad);
//                                             }}
//                                             className="btn btn-primary btn-sm"
//                                         >
//                                             Verify
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//             {InfoModal(data, pic, setPic, handleVerifyNukkad, handleInvalidNukkad)}
//         </div>
//     );
// }

// function InfoModal(data, pic, setPic, handleVerifyNukkad, handleInvalidNukkad) {
//     return (
//         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h1 className="modal-title fs-5" id="exampleModalLabel">Verify {data.nukkadName}</h1>
//                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                         <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
//                             <div className='overflow-auto d-flex flex-column gap-2'>
//                                 <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.ownerPhoto)}>View Owner Photo</button>
//                                 <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.signature)}>View Signature</button>
//                                 <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.fssaiDetails?.certificate)}>View FSSAI Certificate</button>
//                                 <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.gstDetails?.gstCertificate)}>View GST Certificate</button>
//                                 <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.kycDetails?.aadhar)}>View Aadhar</button>
//                                 <button type="button" className="bg-primary text-white rounded-2 h-fit w-fit" onClick={() => setPic(data.kycDetails?.pan)}>View PAN</button>
//                             </div>
//                             <div>
//                                 {pic ? (
//                                     <img src={pic} height={"300px"} loading='lazy' alt="Selected" onLoad={() => console.log('Image loaded')} />
//                                 ) : (
//                                     <div>Loading...</div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => handleInvalidNukkad(data._id)}>Invalid</button>
//                         <button type="button" className="btn btn-primary" onClick={() => handleVerifyNukkad(data._id)}>Verify</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import './Restaurants.css';

// export default function NukkadApproval() {
//     const [nukkadList, setNukkadList] = useState([]);
//     const [filteredNukkadList, setFilteredNukkadList] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('nukkadName');
//     const { API_URL } = config;
//     const [data, setData] = useState({});
//     const [pic, setPic] = useState("");
//     const [showInfoModal, setShowInfoModal] = useState(false);
//     const [showDetailModal, setShowDetailModal] = useState(false);

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//             .then(res => res.json())
//             .then(data => {
//                 const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                 setNukkadList(unverifiedNukkads);
//                 setFilteredNukkadList(unverifiedNukkads);
//                 console.log(unverifiedNukkads)
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching unverified nukkads:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         if (data) {
//             const images = [
//                 data.ownerPhoto,
//                 data.signature,
//                 data.fssaiDetails?.certificate,
//                 data.gstDetails?.gstCertificate,
//                 data.kycDetails?.aadhar,
//                 data.kycDetails?.pan,
//             ];
//             images.forEach((src) => {
//                 if (src) {
//                     const img = new Image();
//                     img.src = src;
//                 }
//             });

//             setPic(data.ownerPhoto);
//         }
//     }, [data]);

//     useEffect(() => {
//         const filtered = nukkadList.filter(nukkad =>
//             nukkad[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredNukkadList(filtered);
//     }, [searchTerm, searchField, nukkadList]);

//     const handleVerifyNukkad = (id) => {
//         fetch(`${API_URL}/api/auth/updateRestaurantById`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ _id: id, updateData: { status: 'verified' } }),
//         })
//             .then(res => res.json())
//             .then(data => {
//                 if (data.executed) {
//                     alert('Nukkad verified successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                             setNukkadList(unverifiedNukkads);
//                             setFilteredNukkadList(unverifiedNukkads);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified nukkads:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify nukkad');
//                 }
//             })
//             .catch(error => {
//                 alert('Failed to verify nukkad', error);
//             });
//     };

//     const handleInvalidNukkad = (id) => {
//         fetch(`${API_URL}/api/auth/updateRestaurantById`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ _id: id, updateData: { status: 'Invalid' } }),
//         })
//             .then(res => res.json())
//             .then(data => {
//                 if (data.executed) {
//                     alert('Nukkad Removed successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                             setNukkadList(unverifiedNukkads);
//                             setFilteredNukkadList(unverifiedNukkads);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified nukkads:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify nukkad');
//                 }
//             })
//             .catch(error => {
//                 alert('Failed to verify nukkad', error);
//             });
//     };

//     const handleOpenInfoModal = (nukkad) => {
//         setData(nukkad);
//         setShowInfoModal(true);
//     };

//     const handleOpenDetailModal = (nukkad) => {
//         setData(nukkad);
//         setShowDetailModal(true);
//     };

//     const handleCloseInfoModal = () => setShowInfoModal(false);
//     const handleCloseDetailModal = () => setShowDetailModal(false);

//     if (loading) {
//         return (
//             <div className="d-flex justify-content-center align-items-center" style={{ height: "98vh" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     return (
//         <div className="container">
//             <h1 className="mt-3 mb-4 font-bold text-xl">Nukkad Approval</h1>

//             <div className="mb-3 flex justify-between items-center w-full max-w-4xl">
//                 <select
//                     className="form-select me-3 w-auto min-w-[150px]"
//                     value={searchField}
//                     onChange={(e) => setSearchField(e.target.value)}
//                 >
//                     <option value="nukkadName">Nukkad Name</option>
//                     <option value="nukkadAddress">Nukkad Address</option>
//                     <option value="city">City</option>
//                     <option value="ownerName">Owner Name</option>
//                     <option value="phoneNumber">Phone Number</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="form-control w-[60%] rounded-xl"
//                 />
//             </div>

//             <div className="card shadow-sm flex flex-col w-full max-w-[60rem] h-[calc(100vh-150px)] rounded-xl overflow-hidden">
//                 <div className="table-responsive flex-1 overflow-y-auto">
//                     <table className="table table-hover mb-0">
//                         <thead className="table-light sticky-top">
//                             <tr>
//                                 <th>Owner Name</th>
//                                 <th>Nukkad Name</th>
//                                 <th>Nukkad Address</th>
//                                 <th>Phone Number</th>
//                                 <th>City</th>
//                                 <th>Actions</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {filteredNukkadList.map(nukkad => (
//                                 <tr key={nukkad._id}>
//                                     <td>{nukkad.ownerName}</td>
//                                     <td>{nukkad.nukkadName}</td>
//                                     <td>{nukkad.nukkadAddress}</td>
//                                     <td>{nukkad.phoneNumber}</td>
//                                     <td>{nukkad.city}</td>
//                                     <td>
//                                         <button
//                                             type="button"
//                                             onClick={() => handleOpenDetailModal(nukkad)}
//                                             className="btn btn-primary btn-sm mr-2"
//                                         >
//                                             Details
//                                         </button>
//                                         <button
//                                             type="button"
//                                             onClick={() => handleOpenInfoModal(nukkad)}
//                                             className="btn btn-success btn-sm"
//                                         >
//                                             Verify
//                                         </button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>

//             {showInfoModal && <InfoModal data={data} pic={pic} setPic={setPic} handleVerifyNukkad={handleVerifyNukkad} handleInvalidNukkad={handleInvalidNukkad} handleClose={handleCloseInfoModal} />}
//             {showDetailModal && <DetailModal data={data} handleClose={handleCloseDetailModal} />}
//         </div>
//     );
// }


// function InfoModal({ data, pic, setPic, handleVerifyNukkad, handleInvalidNukkad, handleClose }) {
//     return (
//         <div className="modal show d-block" style={{ display: 'block' }}>
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title">Verify {data.nukkadName}</h5>
//                         <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="flex justify-between">
//                             <div className="overflow-auto flex flex-col gap-2">
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.ownerPhoto)}>Owner Photo</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.signature)}>Signature</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.fssaiDetails?.certificate)}>FSSAI Certificate</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.gstDetails?.gstCertificate)}>GST Certificate</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.kycDetails?.aadhar)}>Aadhar</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.kycDetails?.pan)}>Pan</button>
//                             </div>

//                             {pic && (
//                                 <div className="overflow-hidden w-[40%]">
//                                     <img className="w-full h-[350px] object-contain" src={pic} alt="restaurant" />
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-danger" onClick={() => handleInvalidNukkad(data._id)}>Mark as Invalid</button>
//                         <button type="button" className="btn btn-success" onClick={() => handleVerifyNukkad(data._id)}>Verify</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }


// function DetailModal({ data, handleClose }) {
//     if (!data) return null;

//     return (
//         <div className="modal show d-block" style={{ display: 'block' }}>
//             <div className="modal-dialog modal-dialog-centered modal-lg">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title text-[20px]">More Details about {data.nukkadName}</h5>
//                         <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Owner Details</h4>
//                             </div>
//                             <div className="card-body d-flex justify-content-between">
//                                 <div className="left-side" style={{ flex: 1 }}>
//                                     <p><strong>Name:</strong> {data.ownerName || 'N/A'}</p>
//                                     <p><strong>Email:</strong> {data.ownerEmail || 'N/A'}</p>
//                                     <p><strong>Phone Number:</strong> {data.ownerContactNumber || 'N/A'}</p>
//                                     <p><strong>Permananet Address:</strong> {data.permananetAddress || 'N/A'}</p>
//                                     <p><strong>Pincode:</strong> {data.pincode || 'N/A'}</p>
//                                 </div>

//                                 <div className="right-side d-flex flex-column align-items-center" style={{ flex: 0.4 }}>
//                                     {data.ownerPhoto ? (
//                                         <img
//                                             src={data.ownerPhoto}
//                                             alt="Owner"
//                                             className="img-fluid mb-2"
//                                             style={{ maxWidth: '100px', borderRadius: '50%' }}
//                                         />
//                                     ) : (
//                                         <div className="no-photo mb-2" style={{ width: '100px', height: '100px', backgroundColor: '#ccc', borderRadius: '50%' }}></div>
//                                     )}

//                                     {data.signature ? (
//                                         <img
//                                             src={data.signature}
//                                             alt="Signature"
//                                             className="img-fluid"
//                                             style={{ maxWidth: '150px' }}
//                                         />
//                                     ) : (
//                                         <div className="no-signature" style={{ width: '150px', height: '40px', backgroundColor: '#ccc' }}></div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="accordion" id="restaurantDetails">
//                             <div className="card mb-3">
//                                 <div className="card-header" id="restaurantHeader">
//                                     <h4 className="mb-0">
//                                         Restaurant Details
//                                     </h4>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className='flex justify-between'>
//                                         <div>
//                                             <p><strong>Nukkad Name:</strong> {data.nukkadName || 'N/A'}</p>
//                                             <p><strong>Email:</strong> {data.ownerEmail || 'N/A'}</p>
//                                             <p><strong>City:</strong> {data.city || 'N/A'}</p>
//                                             <p><strong>Address:</strong> {data.currentAddress || 'N/A'}</p>
//                                             <p><strong>Phone Number:</strong> {data.phoneNumber || 'N/A'}</p>
//                                             <p><strong>Landmark:</strong> {data.landmark || 'N/A'}</p>
//                                         </div>
//                                         <div className='mr-4'>
//                                             <p><strong>Longitude:</strong> {data.longitude || 'N/A'}</p>
//                                             <p><strong>Latitude:</strong> {data.latitude || 'N/A'}</p>
//                                             <p><strong>Operational Hours:</strong></p>
//                                             <ul>
//                                                 {Object.entries(data.operationalHours || {}).map(([day, hours]) => (
//                                                     <li key={day}>{day}: {hours}</li>
//                                                 ))}
//                                             </ul>
//                                             <p><strong>Status:</strong> {data.status || 'N/A'}</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Bank Details:</h4>
//                             </div>
//                             <div className="card-body">
//                                 <p><strong>Account Number:</strong> {data.bankDetails?.accountNo || 'N/A'}</p>
//                                 <p><strong>IFSC Code:</strong> {data.bankDetails?.IFSCcode || 'N/A'}</p>
//                                 <p><strong>Bank Branch:</strong> {data.bankDetails?.bankBranch || 'N/A'}</p>
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Restaurant Menu Images:</h4>
//                             </div>
//                             <div className="card-body">
//                                 {data.restaurantMenuImages && data.restaurantMenuImages.length > 0 ? (
//                                     data.restaurantMenuImages.map((image, index) => (
//                                         <div key={index} className="mb-2">
//                                             <img
//                                                 src={image}
//                                                 alt={`Restaurant menu ${index + 1}`}
//                                                 className="img-fluid"
//                                                 style={{ maxWidth: '155px', marginRight: '10px' }}
//                                             />
//                                             <a href={image} target="_blank" rel="noopener noreferrer">View Image</a>
//                                         </div>
//                                     ))
//                                 ) : (
//                                     <p>No food images available</p>
//                                 )}
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>KYC Details:</h4>
//                             </div>
//                             <div className="card-body">
//                                 <p>Aadhar: <a href={data.kycDetails?.aadhar} target="_blank" rel="noopener noreferrer">View Aadhar</a></p>
//                                 <p>PAN: <a href={data.kycDetails?.pan} target="_blank" rel="noopener noreferrer">View PAN</a></p>
//                             </div>
//                         </div>

//                         <div className="row">

//                             <div className="col-md-6">
//                                 <div className="card mb-3">
//                                     <div className="card-header">
//                                         <h4>FSSAI Details:</h4>
//                                     </div>
//                                     <div className="card-body">
//                                         <p><strong>Certificate Number:</strong> {data.fssaiDetails?.certificateNumber || 'N/A'}</p>
//                                         <p><strong>Certificate Number:</strong> {data.fssaiDetails?.expiryDate || 'N/A'}</p>
//                                         <p><strong>FSSAI Certificate:</strong> <a href={data.fssaiDetails?.certificate} target="_blank" rel="noopener noreferrer">View Certificate</a></p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-6">
//                                 <div className="card mb-3">
//                                     <div className="card-header">
//                                         <h4>GST Details:</h4>
//                                     </div>
//                                     <div className="card-body">
//                                         <p><strong>GST Number:</strong> {data.gstDetails?.gstNumber || 'N/A'}</p>
//                                         <p><strong>GST Certificate:</strong> <a href={data.gstDetails?.gstCertificate} target="_blank" rel="noopener noreferrer">View Certificate</a></p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Referred By:</h4>
//                             </div>
//                             <div className="card-body">
//                                 <p>{data.referred?.referred ? `Referred by: ${data.referred?.reference}` : 'Not referred'}</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }



// import React, { useEffect, useState } from 'react';
// import config from '../../config';
// import DataTable from 'react-data-table-component';
// import './Restaurants.css';

// export default function NukkadApproval() {
//     const [nukkadList, setNukkadList] = useState([]);
//     const [filteredNukkadList, setFilteredNukkadList] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [searchField, setSearchField] = useState('nukkadName');
//     const { API_URL } = config;
//     const [data, setData] = useState({});
//     const [pic, setPic] = useState("");
//     const [showInfoModal, setShowInfoModal] = useState(false);
//     const [showDetailModal, setShowDetailModal] = useState(false);

//     useEffect(() => {
//         fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//             .then(res => res.json())
//             .then(data => {
//                 const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                 setNukkadList(unverifiedNukkads);
//                 setFilteredNukkadList(unverifiedNukkads);
//                 console.log(unverifiedNukkads);
//                 setLoading(false);
//             })
//             .catch(error => {
//                 console.error("Error fetching unverified nukkads:", error);
//                 setLoading(false);
//             });
//     }, []);

//     useEffect(() => {
//         const filtered = nukkadList.filter(nukkad =>
//             nukkad[searchField].toLowerCase().includes(searchTerm.toLowerCase())
//         );
//         setFilteredNukkadList(filtered);
//     }, [searchTerm, searchField, nukkadList]);

//     const handleVerifyNukkad = (id) => {
//         fetch(`${API_URL}/api/auth/updateRestaurantById`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ _id: id, updateData: { status: 'verified' } }),
//         })
//             .then(res => res.json())
//             .then(data => {
//                 if (data.executed) {
//                     alert('Nukkad verified successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                             setNukkadList(unverifiedNukkads);
//                             setFilteredNukkadList(unverifiedNukkads);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified nukkads:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify nukkad');
//                 }
//             })
//             .catch(error => {
//                 alert('Failed to verify nukkad', error);
//             });
//     };

//     const handleInvalidNukkad = (id) => {
//         fetch(`${API_URL}/api/auth/updateRestaurantById`, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({ _id: id, updateData: { status: 'Invalid' } }),
//         })
//             .then(res => res.json())
//             .then(data => {
//                 if (data.executed) {
//                     alert('Nukkad Removed successfully');
//                     setLoading(true);
//                     fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
//                         .then(res => res.json())
//                         .then(data => {
//                             const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
//                             setNukkadList(unverifiedNukkads);
//                             setFilteredNukkadList(unverifiedNukkads);
//                             setLoading(false);
//                         })
//                         .catch(error => {
//                             console.error("Error fetching unverified nukkads:", error);
//                             setLoading(false);
//                         });
//                 } else {
//                     alert('Failed to verify nukkad');
//                 }
//             })
//             .catch(error => {
//                 alert('Failed to verify nukkad', error);
//             });
//     };

//     const handleOpenInfoModal = (nukkad) => {
//         setData(nukkad);
//         setShowInfoModal(true);
//     };

//     const handleOpenDetailModal = (nukkad) => {
//         setData(nukkad);
//         setShowDetailModal(true);
//     };

//     const handleCloseInfoModal = () => setShowInfoModal(false);
//     const handleCloseDetailModal = () => setShowDetailModal(false);

//     if (loading) {
//         return (
//             <div className="d-flex justify-content-center align-items-center" style={{ height: "98vh" }}>
//                 <div className="spinner-grow text-success" role="status">
//                     <span className="visually-hidden">Loading...</span>
//                 </div>
//             </div>
//         );
//     }

//     const columns = [
//         {
//             name: 'Owner Name',
//             selector: row => row.ownerName,
//             sortable: true,
//         },
//         {
//             name: 'Nukkad Name',
//             selector: row => row.nukkadName,
//             sortable: true,
//         },
//         {
//             name: 'Nukkad Address',
//             selector: row => row.nukkadAddress,
//             sortable: true,
//             width: "350px"
//         },
//         {
//             name: 'Phone Number',
//             selector: row => row.phoneNumber,
//             sortable: true,
//         },
//         {
//             name: 'City',
//             selector: row => row.city,
//             sortable: true,
//         },
//         {
//             name: 'Actions',
//             cell: row => (
//                 <div>
//                     <button
//                         type="button"
//                         onClick={() => handleOpenDetailModal(row)}
//                         className="btn btn-primary btn-sm mr-2"
//                     >
//                         Details
//                     </button>
//                     <button
//                         type="button"
//                         onClick={() => handleOpenInfoModal(row)}
//                         className="btn btn-success btn-sm"
//                     >
//                         Verify
//                     </button>
//                 </div>
//             ),
//         }
//     ];

//     const customStyles = {
//         headCells: {
//             style: {
//                 backgroundColor: '#1F2937',
//                 fontWeight: 'bold',
//                 color: '#F9FAFB',
//                 fontSize: '16px',
//                 textTransform: 'uppercase',
//                 padding: '14px 20px',
//                 borderBottom: '2px solid #E5E7EB',
//                 letterSpacing: '0.5px',
//             },
//         },
//         cells: {
//             style: {
//                 padding: '12px 20px',
//                 color: '#4B5563', 
//                 fontSize: '15px', 
//                 borderBottom: '1px solid #E5E7EB', 
//                 backgroundColor: '#FFFFFF',
//                 transition: 'background-color 0.3s ease',
//             },
//         },
//         rows: {
//             style: {
//                 minHeight: '50px',
//                 backgroundColor: '#FFFFFF',
//                 '&:hover': {
//                     backgroundColor: '#F3F4F6',
//                     transition: 'background-color 0.3s ease-in-out',
//                 },
//                 '&:not(:last-of-type)': {
//                     borderBottom: '1px solid #E5E7EB',
//                 },
//             },
//             highlightOnHoverStyle: {
//                 backgroundColor: '#E5E7EB', 
//                 borderRadius: '6px',
//                 outline: '1px solid #D1D5DB',
//                 transition: 'background-color 0.3s ease, outline 0.3s ease',
//             },
//         },
//         pagination: {
//             style: {
//                 backgroundColor: '#F9FAFB',
//                 padding: '12px 20px',
//                 borderTop: '1px solid #E5E7EB',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center', 
//             },
//             pageButtonsStyle: {
//                 borderRadius: '4px', 
//                 height: '36px', 
//                 width: '36px',
//                 padding: '8px',
//                 margin: '0 6px', 
//                 cursor: 'pointer',
//                 transition: 'all 0.2s ease',
//                 color: '#1F2937',
//                 fontSize: '14px', 
//                 fontWeight: '600', 
//                 '&:hover': {
//                     backgroundColor: '#E5E7EB',
//                     transform: 'scale(1.05)', 
//                 },
//                 '&:disabled': {
//                     backgroundColor: '#E5E7EB', 
//                     color: '#9CA3AF', 
//                     cursor: 'not-allowed',
//                 },
//             },
//         },
//     };

//     return (
//         <div className="container">
//             <h1 className="mt-3 mb-4 font-bold text-xl">Nukkad Approval</h1>

//             <div className="mb-3 flex justify-between items-center w-full max-w-4xl">
//                 <select
//                     className="form-select me-3 w-auto min-w-[150px]"
//                     value={searchField}
//                     onChange={(e) => setSearchField(e.target.value)}
//                 >
//                     <option value="nukkadName">Nukkad Name</option>
//                     <option value="nukkadAddress">Nukkad Address</option>
//                     <option value="city">City</option>
//                     <option value="ownerName">Owner Name</option>
//                     <option value="phoneNumber">Phone Number</option>
//                 </select>

//                 <input
//                     type="text"
//                     placeholder={`Search by ${searchField}...`}
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                     className="form-control w-[60%] rounded-xl"
//                 />
//             </div>

//             <DataTable
//                 columns={columns}
//                 data={filteredNukkadList}
//                 progressPending={loading}
//                 pagination
//                 highlightOnHover
//                 pointerOnHover
//                 responsive
//                 customStyles={customStyles}
//             />

//             {showInfoModal && <InfoModal data={data} pic={pic} setPic={setPic} handleVerifyNukkad={handleVerifyNukkad} handleInvalidNukkad={handleInvalidNukkad} handleClose={handleCloseInfoModal} />}
//             {showDetailModal && <DetailModal data={data} handleClose={handleCloseDetailModal} />}
//         </div>
//     );
// }


// function InfoModal({ data, pic, setPic, handleVerifyNukkad, handleInvalidNukkad, handleClose }) {
//     return (
//         <div className="modal show d-block" style={{ display: 'block' }}>
//             <div className="modal-dialog modal-dialog-centered modal-xl">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title">Verify {data.nukkadName}</h5>
//                         <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="flex justify-between">
//                             <div className="overflow-auto flex flex-col gap-2">
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.ownerPhoto)}>Owner Photo</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.signature)}>Signature</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.fssaiDetails?.certificate)}>FSSAI Certificate</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.gstDetails?.gstCertificate)}>GST Certificate</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.kycDetails?.aadhar)}>Aadhar</button>
//                                 <button type="button" className="btn btn-primary" onClick={() => setPic(data.kycDetails?.pan)}>Pan</button>
//                             </div>

//                             {pic && (
//                                 <div className="overflow-hidden w-[40%]">
//                                     <img className="w-full h-[350px] object-contain" src={pic} alt="restaurant" />
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-danger" onClick={() => handleInvalidNukkad(data._id)}>Mark as Invalid</button>
//                         <button type="button" className="btn btn-success" onClick={() => handleVerifyNukkad(data._id)}>Verify</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }


// function DetailModal({ data, handleClose }) {
//     if (!data) return null;

//     return (
//         <div className="modal show d-block" style={{ display: 'block' }}>
//             <div className="modal-dialog modal-dialog-centered modal-lg">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h5 className="modal-title text-[20px]">More Details about {data.nukkadName}</h5>
//                         <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
//                     </div>
//                     <div className="modal-body">
//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Owner Details</h4>
//                             </div>
//                             <div className="card-body d-flex justify-content-between">
//                                 <div className="left-side" style={{ flex: 1 }}>
//                                     <p><strong>Name:</strong> {data.ownerName || 'N/A'}</p>
//                                     <p><strong>Email:</strong> {data.ownerEmail || 'N/A'}</p>
//                                     <p><strong>Phone Number:</strong> {data.ownerContactNumber || 'N/A'}</p>
//                                     <p><strong>Permananet Address:</strong> {data.permananetAddress || 'N/A'}</p>
//                                     <p><strong>Pincode:</strong> {data.pincode || 'N/A'}</p>
//                                 </div>

//                                 <div className="right-side d-flex flex-column align-items-center" style={{ flex: 0.4 }}>
//                                     {data.ownerPhoto ? (
//                                         <img
//                                             src={data.ownerPhoto}
//                                             alt="Owner"
//                                             className="img-fluid mb-2"
//                                             style={{ maxWidth: '100px', borderRadius: '50%' }}
//                                         />
//                                     ) : (
//                                         <div className="no-photo mb-2" style={{ width: '100px', height: '100px', backgroundColor: '#ccc', borderRadius: '50%' }}></div>
//                                     )}

//                                     {data.signature ? (
//                                         <img
//                                             src={data.signature}
//                                             alt="Signature"
//                                             className="img-fluid"
//                                             style={{ maxWidth: '150px' }}
//                                         />
//                                     ) : (
//                                         <div className="no-signature" style={{ width: '150px', height: '40px', backgroundColor: '#ccc' }}></div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="accordion" id="restaurantDetails">
//                             <div className="card mb-3">
//                                 <div className="card-header" id="restaurantHeader">
//                                     <h4 className="mb-0">
//                                         Restaurant Details
//                                     </h4>
//                                 </div>
//                                 <div className="card-body">
//                                     <div className='flex justify-between'>
//                                         <div className='w-1/2'>
//                                             <p><strong>Nukkad Name:</strong> {data.nukkadName || 'N/A'}</p>
//                                             <p><strong>Email:</strong> {data.ownerEmail || 'N/A'}</p>
//                                             <p><strong>City:</strong> {data.city || 'N/A'}</p>
//                                             <p><strong>Address:</strong> {data.currentAddress || 'N/A'}</p>
//                                             <p><strong>Phone Number:</strong> {data.phoneNumber || 'N/A'}</p>
//                                             <p><strong>Landmark:</strong> {data.landmark || 'N/A'}</p>
//                                             <p><strong>Nukkad Address:</strong> {data.nukkadAddress || 'N/A'}</p>
//                                         </div>
//                                         <div className='w-1/2 px-4'>
//                                             <p><strong>Longitude:</strong> {data.longitude || 'N/A'}</p>
//                                             <p><strong>Latitude:</strong> {data.latitude || 'N/A'}</p>
//                                             <p><strong>Operational Hours:</strong></p>
//                                             <ul>
//                                                 {Object.entries(data.operationalHours || {}).map(([day, hours]) => (
//                                                     <li key={day}>{day}: {hours}</li>
//                                                 ))}
//                                             </ul>
//                                             <p><strong>Status:</strong> {data.status || 'N/A'}</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Bank Details:</h4>
//                             </div>
//                             <div className="card-body">
//                                 <p><strong>Account Number:</strong> {data.bankDetails?.accountNo || 'N/A'}</p>
//                                 <p><strong>IFSC Code:</strong> {data.bankDetails?.IFSCcode || 'N/A'}</p>
//                                 <p><strong>Bank Branch:</strong> {data.bankDetails?.bankBranch || 'N/A'}</p>
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Restaurant Menu Images:</h4>
//                             </div>
//                             <div className="card-body">
//                                 {data.restaurantMenuImages && data.restaurantMenuImages.length > 0 ? (
//                                     data.restaurantMenuImages.map((image, index) => (
//                                         <div key={index} className="mb-2">
//                                             <img
//                                                 src={image}
//                                                 alt={`Restaurant menu ${index + 1}`}
//                                                 className="img-fluid"
//                                                 style={{ maxWidth: '155px', marginRight: '10px' }}
//                                             />
//                                             <a href={image} target="_blank" rel="noopener noreferrer">View Image</a>
//                                         </div>
//                                     ))
//                                 ) : (
//                                     <p>No food images available</p>
//                                 )}
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>KYC Details:</h4>
//                             </div>
//                             <div className="card-body">
//                                 <p>Aadhar: <a href={data.kycDetails?.aadhar} target="_blank" rel="noopener noreferrer">View Aadhar</a></p>
//                                 <p>PAN: <a href={data.kycDetails?.pan} target="_blank" rel="noopener noreferrer">View PAN</a></p>
//                             </div>
//                         </div>

//                         <div className="row">

//                             <div className="col-md-6">
//                                 <div className="card mb-3">
//                                     <div className="card-header">
//                                         <h4>FSSAI Details:</h4>
//                                     </div>
//                                     <div className="card-body">
//                                         <p><strong>Certificate Number:</strong> {data.fssaiDetails?.certificateNumber || 'N/A'}</p>
//                                         <p><strong>Certificate Number:</strong> {data.fssaiDetails?.expiryDate || 'N/A'}</p>
//                                         <p><strong>FSSAI Certificate:</strong> <a href={data.fssaiDetails?.certificate} target="_blank" rel="noopener noreferrer">View Certificate</a></p>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-6">
//                                 <div className="card mb-3">
//                                     <div className="card-header">
//                                         <h4>GST Details:</h4>
//                                     </div>
//                                     <div className="card-body">
//                                         <p><strong>GST Number:</strong> {data.gstDetails?.gstNumber || 'N/A'}</p>
//                                         <p><strong>GST Certificate:</strong> <a href={data.gstDetails?.gstCertificate} target="_blank" rel="noopener noreferrer">View Certificate</a></p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="card mb-3">
//                             <div className="card-header">
//                                 <h4>Referred By:</h4>
//                             </div>
//                             <div className="card-body">
//                                 <p>{data.referred?.referred ? `Referred by: ${data.referred?.reference}` : 'Not referred'}</p>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="modal-footer">
//                         <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }




import React, { useEffect, useState } from 'react';
import config from '../../config';
import DataTable from 'react-data-table-component';
import './Restaurants.css';
import { getFirestore, collection, doc, getDoc } from 'firebase/firestore';

export default function NukkadApproval() {
    const [nukkadList, setNukkadList] = useState([]);
    const [filteredNukkadList, setFilteredNukkadList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('nukkadName');
    const { API_URL } = config;
    const [data, setData] = useState({});
    const [pic, setPic] = useState("");
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);

    useEffect(() => {
        fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
            .then(res => res.json())
            .then(data => {
                const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
                setNukkadList(unverifiedNukkads);
                setFilteredNukkadList(unverifiedNukkads);
                console.log(unverifiedNukkads);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching unverified nukkads:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const filtered = nukkadList.filter(nukkad =>
            nukkad[searchField].toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredNukkadList(filtered);
    }, [searchTerm, searchField, nukkadList]);

    const handleVerifyNukkad = (id, hub) => {
        fetch(`${API_URL}/api/auth/updateRestaurantById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ _id: id, updateData: { status: 'verified', hubId: hub } }),
        })
            .then(res => res.json())
            .then(data => {
                if (data.executed) {
                    alert('Nukkad verified successfully');
                    setLoading(true);
                    fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
                        .then(res => res.json())
                        .then(data => {
                            const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
                            setNukkadList(unverifiedNukkads);
                            setFilteredNukkadList(unverifiedNukkads);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error("Error fetching unverified nukkads:", error);
                            setLoading(false);
                        });
                } else {
                    alert('Failed to verify nukkad');
                }
            })
            .catch(error => {
                alert('Failed to verify nukkad', error);
            });
    };

    const handleInvalidNukkad = (id) => {
        fetch(`${API_URL}/api/auth/updateRestaurantById`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ _id: id, updateData: { status: 'Invalid' } }),
        })
            .then(res => res.json())
            .then(data => {
                if (data.executed) {
                    alert('Nukkad Removed successfully');
                    setLoading(true);
                    fetch(`${API_URL}/api/auth/fetchAllRestaurants`)
                        .then(res => res.json())
                        .then(data => {
                            const unverifiedNukkads = data.restaurants.filter(nukkad => nukkad.status === 'unverified');
                            setNukkadList(unverifiedNukkads);
                            setFilteredNukkadList(unverifiedNukkads);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error("Error fetching unverified nukkads:", error);
                            setLoading(false);
                        });
                } else {
                    alert('Failed to verify nukkad');
                }
            })
            .catch(error => {
                alert('Failed to verify nukkad', error);
            });
    };

    const handleOpenInfoModal = (nukkad) => {
        setData(nukkad);
        setShowInfoModal(true);
    };

    const handleOpenDetailModal = (nukkad) => {
        setData(nukkad);
        setShowDetailModal(true);
    };

    const handleCloseInfoModal = () => setShowInfoModal(false);
    const handleCloseDetailModal = () => setShowDetailModal(false);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "98vh" }}>
                <div className="spinner-grow text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    const columns = [
        {
            name: 'Owner Name',
            selector: row => row.ownerName,
            sortable: true,
        },
        {
            name: 'Nukkad Name',
            selector: row => row.nukkadName,
            sortable: true,
        },
        {
            name: 'Nukkad Address',
            selector: row => row.nukkadAddress,
            sortable: true,
            width: "350px"
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber,
            sortable: true,
        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <div>
                    <button
                        type="button"
                        onClick={() => handleOpenDetailModal(row)}
                        className="btn btn-primary btn-sm mr-2"
                    >
                        Details
                    </button>
                    <button
                        type="button"
                        onClick={() => handleOpenInfoModal(row)}
                        className="btn btn-success btn-sm"
                    >
                        Verify
                    </button>
                </div>
            ),
        }
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#1F2937',
                fontWeight: 'bold',
                color: '#F9FAFB',
                fontSize: '16px',
                textTransform: 'uppercase',
                padding: '14px 20px',
                borderBottom: '2px solid #E5E7EB',
                letterSpacing: '0.5px',
            },
        },
        cells: {
            style: {
                padding: '12px 20px',
                color: '#4B5563',
                fontSize: '15px',
                borderBottom: '1px solid #E5E7EB',
                backgroundColor: '#FFFFFF',
                transition: 'background-color 0.3s ease',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
                backgroundColor: '#FFFFFF',
                '&:hover': {
                    backgroundColor: '#F3F4F6',
                    transition: 'background-color 0.3s ease-in-out',
                },
                '&:not(:last-of-type)': {
                    borderBottom: '1px solid #E5E7EB',
                },
            },
            highlightOnHoverStyle: {
                backgroundColor: '#E5E7EB',
                borderRadius: '6px',
                outline: '1px solid #D1D5DB',
                transition: 'background-color 0.3s ease, outline 0.3s ease',
            },
        },
        pagination: {
            style: {
                backgroundColor: '#F9FAFB',
                padding: '12px 20px',
                borderTop: '1px solid #E5E7EB',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            pageButtonsStyle: {
                borderRadius: '4px',
                height: '36px',
                width: '36px',
                padding: '8px',
                margin: '0 6px',
                cursor: 'pointer',
                transition: 'all 0.2s ease',
                color: '#1F2937',
                fontSize: '14px',
                fontWeight: '600',
                '&:hover': {
                    backgroundColor: '#E5E7EB',
                    transform: 'scale(1.05)',
                },
                '&:disabled': {
                    backgroundColor: '#E5E7EB',
                    color: '#9CA3AF',
                    cursor: 'not-allowed',
                },
            },
        },
    };

    return (
        <div className="container">
            <h1 className="mt-3 mb-4 font-bold text-xl">Nukkad Approval</h1>

            <div className="mb-3 flex justify-between items-center w-full max-w-4xl">
                <select
                    className="form-select me-3 w-auto min-w-[150px]"
                    value={searchField}
                    onChange={(e) => setSearchField(e.target.value)}
                >
                    <option value="nukkadName">Nukkad Name</option>
                    <option value="nukkadAddress">Nukkad Address</option>
                    <option value="city">City</option>
                    <option value="ownerName">Owner Name</option>
                    <option value="phoneNumber">Phone Number</option>
                </select>

                <input
                    type="text"
                    placeholder={`Search by ${searchField}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control w-[60%] rounded-xl"
                />
            </div>

            <DataTable
                columns={columns}
                data={filteredNukkadList}
                progressPending={loading}
                pagination
                highlightOnHover
                pointerOnHover
                responsive
                customStyles={customStyles}
            />

            {showInfoModal && <InfoModal data={data} pic={pic} setPic={setPic} handleVerifyNukkad={handleVerifyNukkad} handleInvalidNukkad={handleInvalidNukkad} handleClose={handleCloseInfoModal} />}
            {showDetailModal && <DetailModal data={data} handleClose={handleCloseDetailModal} />}
        </div>
    );
}


function InfoModal({ data, pic, setPic, handleVerifyNukkad, handleInvalidNukkad, handleClose }) {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [hubs, setHubs] = useState([]);
    const [selectedHub, setSelectedHub] = useState('');

    const db = getFirestore();

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const cityCollection = collection(db, 'hubs');
                const cityRef = doc(cityCollection, 'metadata');
                const cityListSnapshot = await getDoc(cityRef);

                if (cityListSnapshot.exists()) {
                    const cityList = cityListSnapshot.data();
                    setCities(Object.keys(cityList));
                } else {
                    console.warn("No metadata found in hubs collection.");
                }
            } catch (error) {
                console.error("Error fetching cities:", error);
            }
        };

        fetchCities();
    }, []);


    useEffect(() => {
        const fetchHubs = async () => {
            if (!selectedCity) return;

            try {
                const cityCollection = collection(db, 'hubs');
                const cityRef = doc(cityCollection, 'metadata');
                const citySnapshot = await getDoc(cityRef);

                if (citySnapshot.exists()) {
                    const metadata = citySnapshot.data();
                    const cityHubs = metadata[selectedCity];
                    if (cityHubs) {
                        setHubs(Object.keys(cityHubs));
                    } else {
                        setHubs([]);
                        console.warn(`No hubs found for city: ${selectedCity}`);
                    }
                }
            } catch (error) {
                console.error("Error fetching hubs:", error);
            }
        };

        fetchHubs();
    }, [selectedCity]);


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="w-[70%] mx-auto flex items-center justify-center">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Verify {data.nukkadName}</h5>
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="flex justify-between gap-4">
                            <div className="overflow-auto flex flex-col gap-2">
                                <button type="button" className="btn btn-primary" onClick={() => setPic(data.ownerPhoto)}>Owner Photo</button>
                                <button type="button" className="btn btn-primary" onClick={() => setPic(data.signature)}>Signature</button>
                                <button type="button" className="btn btn-primary" onClick={() => setPic(data.fssaiDetails?.certificate)}>FSSAI Certificate</button>
                                <button type="button" className="btn btn-primary" onClick={() => setPic(data.gstDetails?.gstCertificate)}>GST Certificate</button>
                                <button type="button" className="btn btn-primary" onClick={() => setPic(data.kycDetails?.aadhar)}>Aadhar</button>
                                <button type="button" className="btn btn-primary" onClick={() => setPic(data.kycDetails?.pan)}>Pan</button>
                            </div>

                            {pic && (
                                <div className="overflow-hidden w-[40%]">
                                    <img className="w-full h-[350px] object-contain" src={pic} alt="restaurant" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer justify-between">
                        <div className='flex gap-3'>
                            <select
                                id="city"
                                className="form-select"
                                value={selectedCity}
                                onChange={(e) => setSelectedCity(e.target.value)}
                            >
                                <option value="">Select a city</option>
                                {cities.map((city) => (
                                    <option key={city} value={city}>
                                        {city.toUpperCase()}
                                    </option>
                                ))}
                            </select>

                            {selectedCity && (
                                <select
                                    id="hub"
                                    className="form-select"
                                    value={selectedHub}
                                    onChange={(e) => setSelectedHub(e.target.value)}
                                >
                                    <option value="">Select a hub</option>
                                    {hubs.map((hub) => (
                                        <option key={hub} value={hub}>
                                            {hub.toUpperCase()}
                                        </option>
                                    ))}
                                </select>
                            )}

                        </div>
                        <div className="gap-3 flex">
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleInvalidNukkad(data._id)}
                            >
                                Mark as Invalid
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => handleVerifyNukkad(data._id, selectedHub)}
                                disabled={!selectedCity || !selectedHub} 
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


function DetailModal({ data, handleClose }) {
    if (!data) return null;

    return (
        <div className="modal show d-block" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-[20px]">More Details about {data.nukkadName}</h5>
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="card mb-3">
                            <div className="card-header">
                                <h4>Owner Details</h4>
                            </div>
                            <div className="card-body d-flex justify-content-between">
                                <div className="left-side" style={{ flex: 1 }}>
                                    <p><strong>Name:</strong> {data.ownerName || 'N/A'}</p>
                                    <p><strong>Email:</strong> {data.ownerEmail || 'N/A'}</p>
                                    <p><strong>Phone Number:</strong> {data.ownerContactNumber || 'N/A'}</p>
                                    <p><strong>Permananet Address:</strong> {data.permananetAddress || 'N/A'}</p>
                                    <p><strong>Pincode:</strong> {data.pincode || 'N/A'}</p>
                                </div>

                                <div className="right-side d-flex flex-column align-items-center" style={{ flex: 0.4 }}>
                                    {data.ownerPhoto ? (
                                        <img
                                            src={data.ownerPhoto}
                                            alt="Owner"
                                            className="img-fluid mb-2"
                                            style={{ maxWidth: '100px', borderRadius: '50%' }}
                                        />
                                    ) : (
                                        <div className="no-photo mb-2" style={{ width: '100px', height: '100px', backgroundColor: '#ccc', borderRadius: '50%' }}></div>
                                    )}

                                    {data.signature ? (
                                        <img
                                            src={data.signature}
                                            alt="Signature"
                                            className="img-fluid"
                                            style={{ maxWidth: '150px' }}
                                        />
                                    ) : (
                                        <div className="no-signature" style={{ width: '150px', height: '40px', backgroundColor: '#ccc' }}></div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="accordion" id="restaurantDetails">
                            <div className="card mb-3">
                                <div className="card-header" id="restaurantHeader">
                                    <h4 className="mb-0">
                                        Restaurant Details
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className='flex justify-between'>
                                        <div className='w-1/2'>
                                            <p><strong>Nukkad Name:</strong> {data.nukkadName || 'N/A'}</p>
                                            <p><strong>Email:</strong> {data.ownerEmail || 'N/A'}</p>
                                            <p><strong>City:</strong> {data.city || 'N/A'}</p>
                                            <p><strong>Address:</strong> {data.currentAddress || 'N/A'}</p>
                                            <p><strong>Phone Number:</strong> {data.phoneNumber || 'N/A'}</p>
                                            <p><strong>Landmark:</strong> {data.landmark || 'N/A'}</p>
                                            <p><strong>Nukkad Address:</strong> {data.nukkadAddress || 'N/A'}</p>
                                        </div>
                                        <div className='w-1/2 px-4'>
                                            <p><strong>Longitude:</strong> {data.longitude || 'N/A'}</p>
                                            <p><strong>Latitude:</strong> {data.latitude || 'N/A'}</p>
                                            <p><strong>Operational Hours:</strong></p>
                                            <ul>
                                                {Object.entries(data.operationalHours || {}).map(([day, hours]) => (
                                                    <li key={day}>{day}: {hours}</li>
                                                ))}
                                            </ul>
                                            <p><strong>Status:</strong> {data.status || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-header">
                                <h4>Bank Details:</h4>
                            </div>
                            <div className="card-body">
                                <p><strong>Account Number:</strong> {data.bankDetails?.accountNo || 'N/A'}</p>
                                <p><strong>IFSC Code:</strong> {data.bankDetails?.IFSCcode || 'N/A'}</p>
                                <p><strong>Bank Branch:</strong> {data.bankDetails?.bankBranch || 'N/A'}</p>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-header">
                                <h4>Restaurant Menu Images:</h4>
                            </div>
                            <div className="card-body">
                                {data.restaurantMenuImages && data.restaurantMenuImages.length > 0 ? (
                                    data.restaurantMenuImages.map((image, index) => (
                                        <div key={index} className="mb-2">
                                            <img
                                                src={image}
                                                alt={`Restaurant menu ${index + 1}`}
                                                className="img-fluid"
                                                style={{ maxWidth: '155px', marginRight: '10px' }}
                                            />
                                            <a href={image} target="_blank" rel="noopener noreferrer">View Image</a>
                                        </div>
                                    ))
                                ) : (
                                    <p>No food images available</p>
                                )}
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-header">
                                <h4>KYC Details:</h4>
                            </div>
                            <div className="card-body">
                                <p>Aadhar: <a href={data.kycDetails?.aadhar} target="_blank" rel="noopener noreferrer">View Aadhar</a></p>
                                <p>PAN: <a href={data.kycDetails?.pan} target="_blank" rel="noopener noreferrer">View PAN</a></p>
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-md-6">
                                <div className="card mb-3">
                                    <div className="card-header">
                                        <h4>FSSAI Details:</h4>
                                    </div>
                                    <div className="card-body">
                                        <p><strong>Certificate Number:</strong> {data.fssaiDetails?.certificateNumber || 'N/A'}</p>
                                        <p><strong>Certificate Number:</strong> {data.fssaiDetails?.expiryDate || 'N/A'}</p>
                                        <p><strong>FSSAI Certificate:</strong> <a href={data.fssaiDetails?.certificate} target="_blank" rel="noopener noreferrer">View Certificate</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card mb-3">
                                    <div className="card-header">
                                        <h4>GST Details:</h4>
                                    </div>
                                    <div className="card-body">
                                        <p><strong>GST Number:</strong> {data.gstDetails?.gstNumber || 'N/A'}</p>
                                        <p><strong>GST Certificate:</strong> <a href={data.gstDetails?.gstCertificate} target="_blank" rel="noopener noreferrer">View Certificate</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-header">
                                <h4>Referred By:</h4>
                            </div>
                            <div className="card-body">
                                <p>{data.referred?.referred ? `Referred by: ${data.referred?.reference}` : 'Not referred'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
